import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzNotificationService } from 'ng-zorro-antd';
import { DistributorService, RootService, StaffsService } from '../../services';
import { removeAllSpacingBetweenWords, getArrayDuplicateAfterCompareTwoArray, formatPhone84 } from 'src/app/helpers';
import { takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { CheckNumberService, NotificationService } from 'src/app/lib';
import { ValidatorService } from 'src/app/lib/validators';
import { AccumualteService } from 'src/app/modules/loyalty/accumulate-point/services';
import { CommonService } from '../../services/common/common.service';
import {track} from "../../../helpers/tracking-teko";

@Component({
  selector: 'app-distributor-add',
  templateUrl: './distributor-add.component.html',
  styleUrls: ['./distributor-add.component.scss']
})
export class DistributorAddComponent implements OnInit, OnDestroy {
  isCheckAccountVietel = false;
  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    private rootService: RootService,
    private distributorService: DistributorService,
    private notification: NzNotificationService,
    private checkNumber: CheckNumberService,
    private notiService: NotificationService,
    private validateS: ValidatorService,
    public modalRef: NzModalRef<DistributorAddComponent>,
    private staffsService: StaffsService,
  ) { }

  unsubscribe$ = new Subject<void>();

  @ViewChild('phone_manager', { static: true }) phone_manager: ElementRef;

  form: FormGroup = this.fb.group({
    name: [null, [Validators.required, Validators.maxLength(255), this.validateS.checkNoWhiteSpaceValidator]],
    phone: [null, [Validators.required]],
    address: [null, Validators.maxLength(255)],
    phone_manager: [null, [Validators.required, this.validateS.checkNumberPhoneValidator, Validators.pattern(/^-?(0|[0-9]\d*)?$/)]],
    logo: [null],
    store_id: [null],
    reciew_sponsor: [false],
    icheck_id: [null, [Validators.required]]
  });

  type: string;
  data: any;
  distributorId: any;

  shops: any[] = [];
  distributors: any[] = [];
  name_qrcode_store: any[] = [];

  icheckId: any;
  isCheckPhone: boolean;
  isName: string;
  delayTimer: any;
  messagePhone = '';
  images: any[] = [];

  openMessage(type: string, title: string, content: any) {
    return this.notification.create(
      type,
      title,
      content
    );
  }
  ngOnInit() {
    this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();
    if (this.type === 'update') {
      // ==============================//
      this.staffsService.findUserSocialByIdOrPhone(this.data.icheck_id, null).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        // if (res.rows.length > 0) {
          this.form.get('phone_manager').setValue(null);
          if (res.data && res.data.avatar_thumbnails) {
            this.form.get('logo').setValue(res.data.avatar_thumbnails.small);
            this.images = [{
              uid: 1,
              name: '',
              status: 'done',
              url: res.data.avatar_thumbnails.small,
              thumbUrl: res.data.avatar_thumbnails.thumbnail,
              id: res.data.id
            }];
          }
          if (res.data && res.data.name) { this.isName = removeAllSpacingBetweenWords(res.data.name); }
        // }
      });
      // ==============================//
      this.distributorId = this.data.id;
      this.form.get('name').setValue(this.data.name);
      this.form.get('phone').setValue(this.data.phone);
      this.form.get('address').setValue(this.data.address);
      this.form.get('store_id').setValue(this.data.store_id);
      this.form.get('reciew_sponsor').setValue(this.data.reciew_sponsor);
      this.form.get('icheck_id').setValue(this.data.icheck_id);
      this.form.get('store_id').disable();
    }
    this.loadShop();
  }

  checkValidatePhone($event) {
    this.findingManager($event.target.value);  
  }
  checkPhoneCustomer(phone: string) {
    // let phoneTrim = phone.replace(/\s/g, '').trim();
    // const isNumber = this.checkNumber.checkNumberInput(phoneTrim);
    // if(isNumber.status) {
    //   const query = {
    //     phone: phoneTrim
    //   }
    //     this.accumulateService.findingCustomer(query).subscribe((res: ResponseModel) => {
    //       if(res.statusCode === 200) {
    //         this.campagin = res.data.rows;
    //         if(this.campagin.length > 0) {
    //           this.customerName = this.campagin[0].name;
    //         } else {
    //           this.notiService.notiWarning('Số điện thoại của bạn chưa tham gia chương trình tích điểm');
    //         }
    //       }
    //     })
    // } else {
    //   this.notiService.notiError(isNumber.message);
    // }
  }

  findingManager(phone) {
    this.staffsService.findUserSocialByIdOrPhone(null, formatPhone84(removeAllSpacingBetweenWords(phone))).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res.data) {
        this.icheckId = res.data.id;
        this.form.patchValue({ icheck_id: this.icheckId });
        this.isCheckPhone = false;
        if (res.data.avatar) {
          this.images = [{
            uid: 1,
            name: '',
            status: 'done',
            url: res.data.avatar_thumbnails.small,
            thumbUrl: res.data.avatar_thumbnails.small,
            id: res.data.avatar
          }];
        }
        if (res.data.name) { this.isName = removeAllSpacingBetweenWords(res.data.name); }
      } else {
        this.isName = '';
        this.isCheckPhone = true;
        this.messagePhone = 'Số điện thoại chưa đăng ký trên iCheck';
      }
    });
  }

  loadShop() {
    forkJoin(
      this.distributorService.allShopQrcode(), /*Tra ve tat ca cac cua hang ben QR code*/
      this.distributorService.allDistributor() /*Tra ve tat ca cac cua hang da duoc doanh nghiep nay phan phoi*/
    ).subscribe(([res1, res2]) => {
      if (res1 && res1.data && res1.data.store) {
        const allShop = res1.data.store;
        if (res2 && res2.data && res2.data.rows) {
          const allDistributor = res2.data.rows;
          let allIdDistributor = [];
          allDistributor.forEach(element => {
            allIdDistributor.push(element.store_id);
          });
          // Neu update thi them id cua hang hien tai vao; Boi vi update khong cho cap nhat cua hang khac;
          if (this.type === 'update') {
            allIdDistributor = allIdDistributor.filter(item => item !== this.data.store_id);
          }
          this.shops = allShop.filter(x => !allIdDistributor.includes(x.id));
        }
      }
    });
  }

  getShopName(data: any) {
    if (data) {
      const arr = getArrayDuplicateAfterCompareTwoArray(this.shops, [data], 'id');
      if (arr.length > 0 && this.form.get('name').value === null) { this.form.get('name').setValue(arr[0].name); }
    }
  }

  uploadImageAttribute($event) {
    switch ($event.type) {
      case 'removed': {
        this.form.get('logo').setValue(null);
        break;
      }
      case 'success': {
        if ($event.file.status === 'done') {
          this.form.patchValue({ logo: $event.file.response.id });
        }
        break;
      }
    }
  }

  onSubmit() {
    if (this.type === 'update') {
      this.form.controls.phone_manager.clearValidators();
      this.form.controls.phone_manager.updateValueAndValidity();
    }
    for (const key of Object.keys(this.form.controls)) {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    }
    if (this.form.invalid || this.isCheckPhone === true) {
      if (this.isCheckPhone === true) {
        this.openMessage('warning', 'Cảnh báo', `Số điện thoại chưa đăng ký trên iCheck`);
      }
      return;
    }
    if(this.form.get('icheck_id').value !== null) {
      delete this.form.value.phone_manager;
    }
    switch (this.type) {
      case 'create': {
        track('manualTrackInteractionContent', {
          contentName: 'Them moi',
          interaction: 'click',
          regionName: 'createStore',
          target: 'storeList',
        });
        let isNumber = this.checkNumber.checkNumberInput(this.form.get('phone').value);
        if(isNumber.status) {
          this.distributorService.create(this.form.value).pipe(takeUntil(this.unsubscribe$)).subscribe(
            (res) => {
              track('alert', {
                alertType: res.statusCode === 200 ? 'success' : 'failed',
                alertMessage: res.statusCode === 200 ? 'Them cua hang thanh cong' : 'Them cua hang that bai'
              });
              if (res.statusCode === 200) {
                this.openMessage('success', 'Thành công', `Thêm thành công`);
                this.form.removeControl('icheck_id');
                this.modalRef.close('OK');
              } else {
                this.openMessage('warning', 'Cảnh báo', `${res.message}`);
              }
            },
            (error) => {
              this.openMessage('error', 'Có lỗi xảy ra !!!', `${error}`);
            }
          );
          break;
        } else {
          this.notiService.notiError(`Số điện thoại nhập ${isNumber.message}`);
          break;
        }
        }
      case 'update': {
        track('manualTrackInteractionContent', {
          contentName: 'Cap nhat',
          interaction: 'click',
          regionName: 'createStore',
          target: 'storeList',
        });
        this.distributorService.update(this.distributorId, this.form.value).pipe(takeUntil(this.unsubscribe$)).subscribe(
          (res) => {
            track('alert', {
              alertType: res.statusCode === 200 ? 'success' : 'failed',
              alertMessage: res.statusCode === 200 ? 'Cap nhat cua hang thanh cong' : 'Cap nhat cua hang that bai'
            });
            if (res.statusCode === 200) {
              this.openMessage('success', 'Thành công', `Cập nhật thành công`);
              this.modalRef.close('OK');
            } else {
              this.openMessage('warning', 'Cảnh báo', `${res.message}`);
            }
          },
          (error) => {
            this.openMessage('error', 'Có lỗi xảy ra !!!', `${error}`);
          }
        );
        break;
      }
    }
  }

  cancel() {
    this.form.reset();
    this.modalRef.close();
    track('manualTrackInteractionContent', {
      contentName: 'Bo qua',
      interaction: 'click',
      regionName: 'createStaff',
      target: 'storeList',
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$.unsubscribe();
  }

}
