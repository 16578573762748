<div class="login">
  <div class="logo"></div>
  <div class="form">
    <p class="title">Liên kết tài khoản</p>
    <div>
      <p class="description">Liên kết tài khoản iCheck với tài khoản QR Code
        để tạo chương trình Loyalty áp dụng cho tem QR code của bạn.</p>
      <nz-card>
        <div nz-row>
          <div nz-col nzSpan="4">
            <img style="width: 60px; height: 60px;" src="{{loyaltyUser?.avatar}}" alt="">
          </div>
          <div nz-col nzSpan="20">
            <h3 class="name-company">{{loyaltyUser?.name}}</h3>
            <span class="phone">{{loyaltyUser?.phone}}</span>
          </div>
        </div>
      </nz-card>

      <div nz-row style="margin-top: 20px;">
        <div nz-col nzSpan="12">
          <button class="cancel" nz-button nzType="default" (click)="cancel()" type="button">Bỏ qua</button>
        </div>
        <div nz-col nzSpan="12">
          <button class="save" nz-button type="button" (click)="syncAcount()">Đồng bộ</button>
        </div>
      </div>
    </div>
  </div>
</div>