import {
  Directive, ElementRef, forwardRef,
  HostListener, Input, Renderer2, Injector,
  OnInit, HostBinding, DoCheck, NgZone, OnDestroy
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, Validator,
  FormControl, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { Subject } from 'rxjs';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';

type NumberType = number | string | null;

let nextUniqueId = 0;
const REGEX_NUMBER = /[^\d.\-e+]/g;

@Directive({
  // tslint:disable-next-line:directive-selector
  // selector: 'input[type="number"],input[type="currency"],input[type="percent"],input[number]',
  selector: '[input[type="number"],input[type="currency"],input[type="percent"],input[number]]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppInputNumberDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AppInputNumberDirective),
      multi: true,
    },
    DecimalPipe
  ],
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
    '[class.text-right]': 'right',
  }
})
// tslint:disable-next-line:max-line-length
export class AppInputNumberDirective implements OnInit, DoCheck, OnDestroy, ControlValueAccessor, Validator {
  controlType = 'customNumber';
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  ngControl: NgControl = null;
  errorState = false;
  id = `attachment-input-${nextUniqueId++}`;
  _validator: Map<string, ValidatorFn> = new Map();

  private _valueAccessor: NumberType;
  @HostBinding('attr.value') @Input()
  get value() { return this._valueAccessor; }
  set value(value) {
    this._valueAccessor = value;
    this.formatValue(value);
    this.stateChanges.next();
  }

  @Input() numberType: 'number' | 'currency' | 'percent' = 'number';
  @Input() type: string;
  @Input() format: string = '1.0-0';
  @Input() formatSeparator: boolean = true;
  @Input() viewFormatFn: (val: any) => string;

  private _placeholder: string;
  @Input()
  get placeholder() { return this._placeholder; }
  set placeholder(val) {
    this._placeholder = val;
    this.stateChanges.next();
  }

  private _required: boolean;
  @Input()
  get required() { return this._required; }
  set required(val) {
    this._required = coerceBooleanProperty(val);
    this.stateChanges.next();
  }

  @Input()
  set min(val) {
    if (coerceNumberProperty(val)) {
      this._validator.set('min', Validators.min(coerceNumberProperty(val)));
    } else {
      this._validator.delete('min');
    }
  }

  @Input()
  set max(val) {
    if (coerceNumberProperty(val)) {
      this._validator.set('max', Validators.max(coerceNumberProperty(val)));
    } else {
      this._validator.delete('max');
    }
  }

  private _readonly: boolean;
  @Input()
  get readonly() { return this._readonly; }
  set readonly(val) {
    this._readonly = coerceBooleanProperty(val);
    this.stateChanges.next();
  }

  private _disabled: boolean;
  @Input()
  get disabled() { return this._disabled; }
  set disabled(val) {
    this._disabled = coerceBooleanProperty(val);
    this.stateChanges.next();
  }

  private _right: boolean;
  @Input()
  get right() { return this._right; }
  set right(val) {
    this._right = coerceBooleanProperty(val);
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() { return this.focused || !this.empty; }
  get empty() { return !this.value; }

  @HostBinding('attr.aria-describedby') describedBy = '';

  constructor(
      public injector: Injector,
      private element: ElementRef<HTMLInputElement>,
      private renderer: Renderer2,
      private ngZone: NgZone,
      private decimalPipe: DecimalPipe) {
  }

  ngOnInit() {
    this._setElementTypeText();
    this.ngControl = this.injector.get(NgControl);

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    if (this.numberType === 'currency' && this.format === '1.0-0') {
      this.format = '1.2-2';
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  ngOnDestroy() {
    this._removeFocusOutsideListener();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this._valueAccessor = this._transformValue(value);
    this.onChange(this._valueAccessor); // notify Angular Validators
  }

  @HostListener('blur')
  _onBlur() {
    if (this.readonly || this.disabled) {
      return;
    }

    this._setElementTypeText();
    this.formatValue(this._valueAccessor);
  }

  @HostListener('focus')
  onFocus() {
    if (this.readonly || this.disabled) {
      return;
    }

    this.unFormatValue();
    this._setElementTypeNumber();
  }

  @HostListener('focusout')
  onFocusOut() {
    this._onBlur();
  }

  onChange = (value: any) => { };
  onTouched: () => void = () => { this.touched = true; };

  writeValue(value: any) {
    this._valueAccessor = this._transformValue(value);
    this.formatValue(this._valueAccessor); // format Value
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = () => {
      this.touched = true;
      fn();
    };

    this._initFocusOutsideListener();
  }

  validate(control: FormControl) {
    const validators = Validators.compose([...this._validator.values()]);
    return validators ? validators(control) : null;
  }

  private _initFocusOutsideListener() {
    this.ngZone.runOutsideAngular(() => {
      this.element.nativeElement.addEventListener('focus', this.onTouched.bind(this));
    });
  }

  private _removeFocusOutsideListener() {
    this.ngZone.runOutsideAngular(() => {
      this.element.nativeElement.removeEventListener('focus', this.onTouched.bind(this));
    });
  }

  private formatValue(value: NumberType) {
    if (value !== null) {
      value = ((value !== undefined && value !== null) ? value : '').toString().replace(REGEX_NUMBER, '');

      this._setElementValue(this._decimalTransform(value), true);
    } else {
      this._setElementValue(this._decimalTransform(), true);
    }
  }

  private unFormatValue() {
    const value = this.element.nativeElement.value;

    if (value) {
      this._setElementValue(value.replace(REGEX_NUMBER, ''));
    } else {
      this._setElementValue(this._decimalTransform());
    }
  }

  private _setElementTypeNumber() {
    this.renderer.setProperty(this.element.nativeElement, 'type', 'number');
  }

  private _setElementTypeText() {
    this.renderer.setProperty(this.element.nativeElement, 'type', 'text');
  }

  private _setElementValue(value, special?) {
    this.renderer.setProperty(this.element.nativeElement, 'value', (
        this.viewFormatFn ?
            this.viewFormatFn(value) :
            (special && value && this.numberType === 'percent' ? `${value}%` : value)
    ));
  }

  private _transformValue(value?) {
    const val = (this.decimalPipe
            .transform(((value !== undefined && value !== null) ? value : '').toString().replace(REGEX_NUMBER, ''), this.format)
        || '');

    if (val === '') {
      return undefined;
    }

    return Number(val.replace(REGEX_NUMBER, ''));
  }

  private _decimalTransform(value?) {
    let val = this.decimalPipe.transform((value !== undefined && value !== null) ? value : '', this.format) || '';

    if (!this.formatSeparator) {
      val = val.replace(REGEX_NUMBER, '');
    }

    return val;
  }
}
