<form [formGroup]="filterForm" (ngSubmit)="onFormSubmit()">
  <section>
    <div *ngFor="let item of configs;index as i" style="display: inline-flex;">
      <input class="filterQ" nz-input nzShowSearch nzAllowClear [placeholder]="item.name" *ngIf="item.type === 'text'" [formControlName]="item.key"/>
      <input class="filterQ" type="number" nzShowSearch nzAllowClear nz-input [placeholder]="item.name" *ngIf="item.type === 'number'" [formControlName]="item.key"/>
      <nz-select  class="filterQ" nzShowSearch nzAllowClear [formControlName]="item.key" *ngIf="item.type === 'select-box'" [nzPlaceHolder]="item.name" >
          <nz-option class="placeholder" selected disabled [nzValue]="null">{{item.name}}</nz-option>
          <nz-option [nzValue]="option.value" [nzLabel]="option.name" *ngFor="let option of item?.settingComponentConfig?.options; index as i"></nz-option>
      </nz-select>

      <nz-select  class="filterQ" (nzScrollToBottom)="loadStore('more')" [nzDropdownRender]="renderTemplateStore" nzShowSearch nzAllowClear [formControlName]="item.key" *ngIf="item.type === 'select-box-custom' && item.key === 'store_icheck_id'" [nzPlaceHolder]="item.name" >
        <nz-option class="placeholder" selected disabled [nzValue]="null">{{item.name}}</nz-option>
        <nz-option [nzValue]="option.id" [nzLabel]="option.name" *ngFor="let option of stores; index as i"></nz-option>
      </nz-select>
      <ng-template #renderTemplateStore>
        <nz-spin *ngIf="isLoadingStore"></nz-spin>
      </ng-template>
      <nz-select  class="filterQ" (nzScrollToBottom)="loadGift('more')" [nzDropdownRender]="renderTemplateGift" nzShowSearch nzAllowClear [formControlName]="item.key" *ngIf="item.type === 'select-box-custom' && item.key === 'gift_id'" [nzPlaceHolder]="item.name" >
        <nz-option class="placeholder" selected disabled [nzValue]="null">{{item.name}}</nz-option>
        <nz-option [nzValue]="option.gift.id" [nzLabel]="option.gift.name" *ngFor="let option of gifts; index as i"></nz-option>
      </nz-select>
      <ng-template #renderTemplateGift>
        <nz-spin *ngIf="isLoadingGift"></nz-spin>
      </ng-template>
      <!-- <nz-range-picker [formControlName]="item.key" nzShowSearch nzAllowClear [nzRanges]="item?.settingComponentConfig?.ranges" [nzPlaceHolder]="['Từ ngày', 'Đến ngày']" nzFormat="dd/MM/yyyy" *ngIf="item.type === 'daterange'">
      </nz-range-picker> -->
      <nz-date-picker [ngStyle]="item.key== 'to' ? {'margin-left': '5px'}: ''" *ngIf="item.type === 'date'" [formControlName]="item.key" [nzFormat]="dateFormat" [nzPlaceHolder]="item.name"></nz-date-picker>
      <!-- <nz-date-picker *ngIf="item.type === 'to'" [formControlName]="item.key" [nzFormat]="dateFormat" [nzPlaceHolder]="item.name"></nz-date-picker> -->
    </div>
    <button nz-button [ngClass]="!isCheckAccountVietel ? 'ant-btn-primary' : 'viettel-button'" style="margin-left: 5px" (click)="search()">Tìm kiếm</button>
    <button class="resetBtn" title="Làm mới" (click)="reset()">
      <img src="/assets/search_reset.png" style="display: inline-block;width: 25px;">
    </button>
  </section>
</form>