<div class="login">
  <div class="logo"></div>
  <div class="form-container">
    <p class="title">Xác nhận đăng ký</p>
    <form nz-form [formGroup]="confirmOTP" autocomplete="false" novalidate="true" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control style="margin-top: 20px;">
          <nz-form-label [nzSpan]="24">Mã kích hoạt đã được gửi đến số điện thoại:</nz-form-label>
          <h4 nz-title>{{userObj.phone}}</h4>
          <input style="margin-top: 30px;" nz-input formControlName="otp" placeholder="Mã xác nhận" type="text" />
          <ng-template #otp let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập mã xác nhận</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div nz-row style="margin-top: 50px;">
        <div nz-col nzSpan="12">
          <a class="back-login" routerLink="/auth/register">Quay lại</a>
        </div>
        <div nz-col nzSpan="12">
          <button class="save" nz-button nzType="default" type="submit">Xác nhận</button>
        </div>
      </div>
    </form>
  </div>
</div>