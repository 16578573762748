import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SyncService } from 'src/app/shared/services';

@Component({
  selector: 'app-sync-account',
  templateUrl: './sync-account.component.html',
  styleUrls: ['./sync-account.component.scss']
})
export class SyncAccountComponent implements OnInit {

  loading = false;
  error = '';
  submitted = false;
  /*user qrcode*/
  loyaltyUser;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private syncService: SyncService
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      let phone = params['phone'];
      let name = params['name'];
      let avatar = params['avatar'] || '';
      let access_token = params['access_token'];
      if (id && phone && name) {
        this.loyaltyUser = {
          name: name,
          id: id,
          phone: phone,
          avatar: avatar,
          access_token: access_token
        }
      }
    });
  }

  syncAcount() {
    this.syncService.saveUserLoyalty(this.loyaltyUser);
    this.router.navigate(['/auth/sync-form']);
  }

  cancel() {
    this.router.navigate(['/auth/login']);
  }
}
