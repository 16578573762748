import { Injectable } from '@angular/core';
import { BaseLoyaltyService } from 'src/app/shared/services/base-loyalty.service';
import { Observable } from 'rxjs';

@Injectable()
export class QRCodeService extends BaseLoyaltyService {
  mergeArray(inputArr) {
    const result: any = [];
    return new Promise((resolve, reject) => {
      inputArr.sort((a, b) => a.id_end - b.id_end);
      for (const item of inputArr) {
        if (result.length === 0) {
          result.push(item);
          continue;
        }
        if (result[result.length - 1].end + 1 === item.start) {
          result[result.length - 1].end = item.end;
        } else {
          result.push(item);
        }
      }
      resolve(result);
    });
  }
  check(inputPrefix: any, serial: any) {
    return new Promise((resolve, reject) => {
      const res = { error: [], status: false };
      for (let i = 0; i < inputPrefix.length; i++) {
        const item = inputPrefix[i];
        const nextItem = inputPrefix[i + 1];
        if (serial.start >= item.id_start && serial.end <= item.id_end) {
          res.status = true;
          resolve(res);
        }
        if (item.id_start <= serial.start && item.id_end < serial.end) {
          res.error.push({
            start: item.id_end + 1,
            end: nextItem ? nextItem.id_start - 1 : serial.end
          });
          continue;
        }
        if (item.id_end < serial.end) {
          res.error.push({
            start: item.id_end + 1,
            end: nextItem ? nextItem.id_start - 1 : serial.end
          });
          continue;
        }
        if (item.id_start > serial.end) {
          res.error.push({
            start: serial.start,
            end: item.id_start
          });
          continue;
        }
      }
      resolve(res);
    });
  }
  checkStampSerial(stamp): Observable<any> {
    return this.post<any>(`/qrcode/check-serial`, stamp);
  }
}
