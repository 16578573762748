import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { NzModalService, TransferItem, NzConfigService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { track } from 'src/app/helpers/tracking-teko';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {
  isVisible = false;
  index = 1;
  isCheckAccountVietel = false;
  name: any;
  title: any;
  data: any;
  id: any;
  // tslint:disable-next-line:variable-name
  @Output() emitData = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private notification: NzConfigService,
    public commonService: CommonService

  ) {
  }

  ngOnInit() {
    this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();
  }


  handleCancel(): void {
    this.isVisible = false;
  }

  showModal(data: any, id?: any) {
    this.isVisible = true;
    this.data = data;
    if (typeof id === 'string') {
      let a = [];
      a.push(id)
      this.id = a;
    } else {
      this.id = id;
    }
  }

  onSubmit() {
    track('manualTrackInteractionContent', {
      contentName: 'Xac nhan',
      interaction: 'click',
      regionName: 'popup'
    });
    this.emitData.emit(this.id);
    this.isVisible = false;
  }

}
