import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'i-select',
  templateUrl: './selectbox.component.html',
  styleUrls: ['./selectbox.component.css']
})
export class SelectboxComponent implements OnInit {
  @Input()
    member: any = {};
  public tips : string;
  public name : string;
  public options : any = [];
  public required : boolean;
  public value : any;
  public placeholder: string;
  public layout : string = 'half';
  public conf : any = {};
  public label: string;
  public addItem: boolean = false;


  constructor() {
  }

  ngOnInit() {

    this.options = this.member.options;
    // this.options = _.map(this.member.options, e => {
    //   return {
    //     id: e.value,
    //     text: e.name
    //   }
    // })
    this.placeholder = this.member.placeholder;
    this.tips = this.member.tips;
    this.required = this.member.required;
    this.value = this.member.value;
    this.name = this.member.name;
    this.label = this.member.label;
    this.conf = {
      multiple : this.member.multiple || false,
      minimumResultsForSearch : this.member.show_search || -1
    }
    this.addItem = this.member.addItem ? true : false;
  }
  onBlur()
  {}
  onBlurSelect()
  {}

  @Output() valueChange = new EventEmitter();
  eventChange() { // You can give any function name
      this.valueChange.emit(this.value);
  }

}
