import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './jwt.interceptor';
import { NgZorroAntdModule } from 'ng-zorro-antd';

import { ForgotPassComponent } from './forgot-pass';
import { ResetPassWordComponent } from './reset-pass';
import { ConfirmOTPComponent } from './confirm-otp';
import { SyncAccountComponent, SyncFormComponent, SyncSuccessComponent, SyncFaildComponent } from './sync-account';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BlockUserComponent } from './block-user/block-user.component';

@NgModule({
  declarations: [LoginComponent, RegisterComponent,
    ForgotPassComponent, ResetPassWordComponent,
    ConfirmOTPComponent, SyncAccountComponent,
    SyncFormComponent, SyncSuccessComponent,
    SyncFaildComponent, ChangePasswordComponent, BlockUserComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule
  ],
  exports: [
    ChangePasswordComponent
  ],
  entryComponents: [
    ChangePasswordComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
})
export class AuthModule { }
