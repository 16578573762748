<div class="modal" *ngIf="isVisible">
    <!-- Modal content -->
    <div class="modal-content" style="width:500px">
      <div class="headModal ">
        <div class="headModalTitle"> {{data.label}}</div>
        <div>
          <button class="close" (click)="handleCancel()">&times;</button>
        </div>
      </div>

      <div class="contentModal clearfix">
        <div class="contentModalLeft">
          <i nz-icon nzType="question-circle-o" style="color:#e2541b;font-size: 28px"></i>
        </div>
        <div class="contentModalRight" >
          <div [innerHTML]="data.value" >
          </div>
        </div>
      </div>
      <div class="footerModal">
        <button nz-button [ngClass]="!isCheckAccountVietel ? 'ant-btn-primary' : 'viettel-button'" type="submit" class="" (click)="onSubmit()">Xác nhận</button>
        <button nz-button nzType="default" (click)="handleCancel()" class="btn-df">Bỏ qua</button>
      </div>
    </div>
</div>
