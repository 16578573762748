<div class="login" [ngClass]="{ 'background-viettel': isVersionViettel, 'default-backgound': !isVersionViettel }">
  <div class="logo" *ngIf="!isVersionViettel"></div>

  <div class="form">
    <div style="margin-top: 10px;" *ngIf="isVersionViettel" class="d-flex justify-content-center align-items-center">
      <img style=" margin: 10; width:10rem" src="/assets/images/viettel/vmkt-logo.png" routerLink="/loyalty/home">
    </div>
    <p class="title" [style.color]="isVersionViettel ? '#EE0033' : ''">Quên mật khẩu</p>
    <form nz-form [formGroup]="frmForgot" autocomplete="false" novalidate="true" (ngSubmit)="submitForm()">
      <p class="description">Nhập Số điện thoại của tài khoản cần thiết lập lại mật khẩu</p>
      <nz-form-item style="margin-top: 20px;">
        <nz-form-control [nzSpan]="24" [nzErrorTip]="username">
          <input nz-input formControlName="phone" placeholder="Số điện thoại" class="username" type="text" />
          <ng-template #username let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập số điện thoại</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <div nz-row style="margin-top: 80px;">
        <div nz-col nzSpan="12">
          <a class="back-login" routerLink="/auth/login">Quay lại đăng nhập</a>
        </div>
        <div nz-col nzSpan="12" style="text-align: right;">
          <button class="save" nz-button type="submit" [style.background]="isVersionViettel ? '#EE0033' : ''">Tiếp
            tục</button>
        </div>
      </div>
    </form>
  </div>
  <div style="margin-top: 40px" *ngIf="isVersionViettel">
    <div class="text-center" style="color: white"> Sản phẩm được hợp tác và phát triển bởi Viettel Telecom và iCheck
    </div>
    <!-- <p class="text-center" style="color: white"> Copyright by iCheck (Bản Quyền thuộc về iCheck)</p> -->
    <p class="text-center" style="color: white"> Tổng đài CSKH: 18008000 (nhánh 6) - Tư vấn dịch vụ: 18008168 - Email:
      cskh@viettel.com.vn</p>
  </div>
</div>