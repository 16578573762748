import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQrcodeService } from 'src/app/shared/services';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-block-user',
  templateUrl: './block-user.component.html',
  styleUrls: ['./block-user.component.scss']
})
export class BlockUserComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authQrcodeService: AuthQrcodeService,
    private authService: AuthService,
  ) { }

  reason = '';
  ngOnInit() {
    const body = {};
    this.authQrcodeService.login(body).subscribe(res => {
      this.reason = res.data.reason;
    });
  }

  back() {
    localStorage.clear();
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}
