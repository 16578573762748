import { Injectable, TemplateRef } from "@angular/core";
import { NzNotificationService } from 'ng-zorro-antd';

@Injectable()
export class NotificationService {
  constructor(private notification: NzNotificationService) {
    notification.config({
      nzPlacement: 'bottomRight',
      nzMaxStack: 1
    });
  }
  notiError(message: string) {
    this.notification.create(
      'error',
      `LỖI`,
      `${message}`
    );
  }
  notiSuccess(message: string) {
    this.notification.create(
      'success',
      `THÀNH CÔNG`,
      `${message}`
    );
  }
  notiWarning(message: string) {
    this.notification.create(
      'warning',
      `THÔNG BÁO`,
      `${message}`
    );
  }
  notiInfo(message: string) {
    this.notification.create(
      'info',
      `THÔNG TIN`,
      `${message}`
    );
  }
  notiForTemplate(template: TemplateRef<object>) {
    this.notification.template(template);
  }
  remove() {
    this.notification.remove();
  }
}
