import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoyaltyRoutingModule } from './loyalty-routing.module';
import { LoyaltyComponent } from './loyalty.component';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { LoyaltytDirective } from 'src/app/loyalty.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { DragDropDirective } from './directive/drag-drop.directive';

@NgModule({
  declarations: [
    LoyaltyComponent,
    LoyaltytDirective,
    DragDropDirective,
    // ExchangeGiftComponent,
    // ExchangeGiftStoreComponent
  ],
  exports: [
    DragDropDirective
  ],
  imports: [
    CommonModule,
    LoyaltyRoutingModule,
    NgZorroAntdModule,
    SharedModule,
    AuthModule,
    NzSpinModule,
  ]
})
export class LoyaltyModule { }
