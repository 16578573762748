<div class="login" [ngClass]="{ 'background-viettel': isVersionViettel, 'default-backgound': !isVersionViettel }">
  <div class="logo" *ngIf="!isVersionViettel"></div>

  <div class="form">
    <div style="margin-top: 10px;" *ngIf="isVersionViettel" class="d-flex justify-content-center align-items-center">
      <img style=" margin: 10; width:10rem" src="/assets/images/viettel/vmkt-logo.png" routerLink="/loyalty/home">
    </div>
    <p class="title">Đặt lại mật khẩu</p>
    <form nz-form [formGroup]="frmOTP" autocomplete="false" novalidate="true" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control [nzSpan]="24">
          <p class="description">Mã kích hoạt đã được gửi đến số điện thoại</p>
          <h3 class="phone">{{frmOTP.value.phone}}</h3>
          <input nz-input formControlName="otp" placeholder="Mã xác nhận" type="text" />
          <ng-template #otp let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập mã xác nhận</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="24">
          <input nz-input formControlName="password" placeholder="Mật khẩu mới" type="password" />
          <ng-template #otp let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập mật khẩu mới</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSpan]="24">
          <input nz-input formControlName="confirmed_password" placeholder="Xác nhận mật khẩu" type="password" />
          <ng-template #otp let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập lại mật khẩu</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <p class="error" *ngIf="isNotMatch"><i>Mật khẩu chưa trùng khớp</i></p>
      <div nz-row style="margin-top: 20px;">
        <div nz-col nzSpan="12">
          <a class="back-login" routerLink="/auth/forgot">Lấy lại OTP</a>
        </div>
        <div nz-col nzSpan="12">
          <button [style.background]="isVersionViettel ? '#EE0033' : '#199ed8'" class="save" nz-button nzType="default"
            type="submit">Đặt lại mật khẩu</button>
        </div>
      </div>
    </form>
  </div>
  <div style="margin-top: 40px" *ngIf="isVersionViettel">
    <div class="text-center" style="color: white"> Sản phẩm được hợp tác và phát triển bởi Viettel Telecom và iCheck
    </div>
    <!-- <p class="text-center" style="color: white"> Copyright by iCheck (Bản Quyền thuộc về iCheck)</p> -->
    <p class="text-center" style="color: white"> Tổng đài CSKH: 18008000 (nhánh 6) - Tư vấn dịch vụ: 18008168 - Email:
      cskh@viettel.com.vn</p>
  </div>
</div>