import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-otp',
  templateUrl: './confirm-otp.component.html',
  styleUrls: ['./confirm-otp.component.scss']
})
export class ConfirmOTPComponent implements OnInit {

  confirmOTP: FormGroup;
  loading = false;
  submitted = false;
  userObj: any = {};
  error = '';

  constructor(
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.authService.otpObj.subscribe(res => {
      this.userObj = res;
    })
  }


  createForm() {
    this.confirmOTP = this._formBuilder.group({
      otp: [null, [Validators.required]],
      phone: ['0']
    });
  }

  submitForm() {
  }

}
