import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ListComponentComponent } from 'src/app/shared/components/list-component/list-component.component';
import { NzModalService, NzNotificationService, NzModalRef } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { CrudCodeService } from 'src/app/shared/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportShareComponent extends ListComponentComponent implements OnInit, OnDestroy {
  isCheckAccountVietel = false;
  constructor(
    public modalService: NzModalService,
    public notification: NzNotificationService,
    public router: Router,
    public commonService: CommonService,
    public crudCodeService: CrudCodeService,
    private formBuilder: FormBuilder,
    public modalRef: NzModalRef<ExportShareComponent>) {
    super(modalService, notification, router, crudCodeService, commonService);
  }

  @Input() filter;
  @Input() endpoint;
  @Input() is_not_body;
  frmExport: FormGroup;
  ngOnInit() {
    this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();

    this.createForm();
  }

  createForm() {
    return new Promise((resolve) => {
      this.frmExport = this.formBuilder.group({
        email: [null, [
          Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'),
          Validators.required,
          Validators.maxLength(255)
        ]]
      });
      resolve();
    });
  }

  submitForm() {
    for (const key of Object.keys(this.frmExport.controls)) {
      this.frmExport.controls[key].markAsDirty();
      this.frmExport.controls[key].updateValueAndValidity();
    }
    let body: any = {};
    let param: any = {};
    if(this.is_not_body) {
      body.email = this.frmExport.value.email;
      param = Object.assign({}, this.filter);
    } else {
      body = this.filter;
      body.email = this.frmExport.value.email;
      param = null;
    }
    if (this.frmExport.valid) {
      this.postData(`${this.endpoint}`, body, param).then(res => {
        if (res) {
          this.frmExport.reset();
          this.modalRef.close(res);
        }
      });
    }
  }

  cancel() {
    this.modalRef.close();
  }
}
