import { Component, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter, Input } from '@angular/core';
import { UploadXHRArgs, UploadChangeParam, UploadFile, InputBoolean, InputNumber, NzMessageService } from 'ng-zorro-antd';
import { of, timer, Observable, Observer, from } from 'rxjs';
import { map, take, tap, switchMap } from 'rxjs/operators';
import { UploadService } from 'src/app/shared/services/upload.service';

@Component({
  selector: 'con-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  @Input() @InputNumber() maxSize = 0;
  @Input() fileType: string;
  @Input() fileExt: string;
  @Input() @InputBoolean() multiple: boolean = false;
  @Output() change = new EventEmitter<UploadChangeParam>();
  @Output() done = new EventEmitter<void>();

  @Input() sizes: any[] = [];
  @Output() returnSize = new EventEmitter<any>();
  size: string;

  showUploadButton = true;
  uploadError: string;

  constructor(
    private upload: UploadService,
    private message: NzMessageService,
  ) { }

  ngOnInit() {
  }


  beforeUpload = (file: UploadFile) => {
    const promise = new Promise<UploadFile>((resolve, reject) => {
      if (!this.isImage(file.type)) {
        resolve(file);
      }

      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        file.dimensions = {
          width,
          height
        };
        resolve(file);
      };
    });

    return from(promise).pipe(map(file => {
      if (this.fileType) {
        const type = file.type;

        if (!type || this.fileType.indexOf(type) < 0) {
          this.message.create('error', 'Định dạng file không được hỗ trợ');
          return false;
        }
      }

      if (this.fileExt) {
        const ext = file.name.split('.').pop().toLowerCase();

        if (!ext || this.fileExt.toLowerCase().indexOf(ext) < 0) {
          this.message.create('error', 'Định dạng file không được hỗ trợ');
          return false;
        }
      }

      if (this.sizes.length) {
        if (!(file.dimensions.width == this.sizes[0].width && file.dimensions.height == this.sizes[0].height)) {
          this.message.create("error", `Kích cỡ hình ảnh không phù hợp ( ${this.sizes[0].width} x ${this.sizes[0].height} )`);
          return false;
        }
      }
      return true;

    }));
  }

  tusUpload = (item: UploadXHRArgs) => {
    // return this.upload.create(item.file.size, {
    //   filename: item.file.name,
    //   filetype: item.file.type
    // }).pipe(
    //   take(1),
    //   map(({ fileId, uploadUrl }) => {
    //     const upload = new tus.Upload(item.file as any, {
    //       uploadUrl,
    //       uploadSize: item.file.size,
    //       endpoint: item.action,
    //       retryDelays: [0, 3000, 5000, 10000, 20000],
    //       onError: (error: Error) => {
    //         item.onError!(error, item.file!);
    //       },
    //       onProgress: (bytesUploaded, bytesTotal) => {
    //         item.onProgress!({
    //           percent: (bytesUploaded / bytesTotal * 100)
    //         }, item.file!);
    //       },
    //       onSuccess: () => {
    //         const subscription = timer(0, 1000).pipe(
    //           switchMap(() => this.upload.getFile(fileId))
    //         ).subscribe(file => {
    //           if (file.status === 1) {
    //             subscription.unsubscribe();
    //             item.onSuccess!({
    //               id: file.id,
    //               url: file.source || file.url,
    //               metadata: file.metadata
    //             }, item.file!, null);
    //           }
    //         });
    //       }
    //     });

    //     return upload;
    //   })
    // ).subscribe(upload => upload.start());
    let a = [];
    if (item.file) {
      return this.upload.postImg(item.file).subscribe(
        (res) => item.onSuccess!({
          id: res.data.fileId,
          url: res.data.url,
        }, item.file!, null),
        (err) => {
          this.message.create("error", `Ảnh quá nặng vui lòng upload ảnh nhẹ hơn.`);
        }
      );
    }
  }

  onChange($event: UploadChangeParam) {
    if ($event.fileList.filter(file => file.status !== 'done').length > 0) {
      this.showUploadButton = false;
    } else {
      this.showUploadButton = true;
      this.done.emit();
    }

    this.change.emit($event);
  }

  protected isImage(type: string) {
    return type && type.split('/')[0] === 'image';
  }

}
