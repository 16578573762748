import { Injectable } from "@angular/core";
import { FormControl } from '@angular/forms';

@Injectable()
export class ValidatorService {
  /**
   * @author Zin
   * @param control nhận vào giá trị trên form
   */
  checkNoWhiteSpaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  checkNumberPhoneValidator(control: FormControl) {
    let result = { 'phone': true };
    const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
    if (control.value && control.value.length > 0) {
      if(vnf_regex.test(control.value) === false) {
        return result;
      } else {
        return null;
      }
    }
  }
}