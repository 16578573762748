import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { ModalOutDateComponent } from '../modal-out-date/modal-out-date.component';

@Component({
  selector: 'app-date-expired',
  templateUrl: './date-expired.component.html',
  styleUrls: ['./date-expired.component.scss']
})
export class DateExpiredComponent implements OnInit {

  form = this.fb.group({});
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    public modalRef: NzModalRef<ModalOutDateComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
    // localStorage.removeItem('loyaltyUser');
    // localStorage.clear();
    // location.reload();
    this.modalRef.close();
  }

  openModal() {
    this.modalRef.close();
    console.log('date');
  }
}
