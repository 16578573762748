<div class="login" [ngClass]="{ 'background-viettel': isVersionViettel, 'default-backgound': !isVersionViettel }">
  <div class="logo" *ngIf="!isVersionViettel"></div>

  <div class="form">
    <div style="margin-top: 10px;" *ngIf="isVersionViettel" class="d-flex justify-content-center align-items-center">
      <!-- <img style=" margin: 62px 10px" src="/assets/images/icon_footer_viettel.png">
      <img style="margin: 62px 10px" src="/assets/images/icon_footer_icheck.png"> -->
      <img style=" margin: 10; width:10rem" src="/assets/images/viettel/vmkt-logo.png" routerLink="/loyalty/home">
    </div>
    <div class="title" [ngStyle]="{
        color: isVersionViettel ? '#EE0033' : '#e2541b'
    }">Đăng nhập</div>
    <form nz-form>
      <nz-form-item style="margin-left: 20px;margin-top: 20px">
        <nz-form-control>
          <nz-input-group [nzSuffix]="mail">
            <input [(ngModel)]="user.userName" [ngModelOptions]="{standalone: true}" class="phone" nz-input
              placeholder="Số điện thoại">
          </nz-input-group>
          <ng-template #mail>
            <i [ngClass]="!user.userName? 'icon' : 'iconValid'" nz-icon nzType="phone"></i>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="margin-left: 20px;margin-top: 20px">
        <nz-form-control>
          <nz-input-group [nzSuffix]="lock">
            <input [(ngModel)]="user.password" [ngModelOptions]="{standalone: true}" class="password" [type]="password"
              nz-input placeholder="Mật khẩu">
          </nz-input-group>
          <ng-template #lock>
            <i [ngClass]="!user.password? 'icon' : 'iconValid'"
              (click)="showPass((password == 'password') ? 'eye' : 'eye-invisible')" nz-icon
              [nzType]="(password == 'password') ? 'eye' : 'eye-invisible'"></i>
          </ng-template>
        </nz-form-control>
        <div *ngIf="message" [ngStyle]="styleMessage">
          <i>{{message}}</i>
        </div>
      </nz-form-item>
      <nz-form-item style="margin-left: 20px;margin-top: 20px">
        <nz-form-control style="line-height: 40px">
          <a class="forget" routerLink="/auth/forgot" (click)="forgotPassword()">Quên mật khẩu</a>
          <button
            [ngClass]="!isVersionViettel ? ((!user.userName || !user.password)? 'btnLogin' : 'btnLoginValid') : 'btnLoginViettel'"
            nz-button (click)="login()">
            <span>Đăng nhập</span></button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <div style="margin-top: 40px" *ngIf="isVersionViettel">
    <div class="text-center" style="color: white"> Sản phẩm được hợp tác và phát triển bởi Viettel Telecom và iCheck
    </div>
    <!-- <p class="text-center" style="color: white"> Copyright by iCheck (Bản Quyền thuộc về iCheck)</p> -->
    <p class="text-center" style="color: white"> Tổng đài CSKH: 18008000 (nhánh 6) - Tư vấn dịch vụ: 18008168 - Email:
      cskh@viettel.com.vn</p>
    <!-- <p class="text-center" style="color: white">Tổng đài CSKH: 18008000 (nhánh 6)</p>
    <p class="text-center" style="color: white">Tư vấn dịch vụ: 18008168</p>
    <p class="text-center" style="color: white">Email: cskh@viettel.com.vn</p> -->
  </div>
</div>
