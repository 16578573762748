import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd';
import {AuthService} from '../../../modules/auth/auth.service';

@Component({
  selector: 'app-warning-permission',
  templateUrl: './warning-permission.component.html',
  styleUrls: ['./warning-permission.component.scss']
})
export class WarningPermissionComponent implements OnInit {

  @Input() message;
  constructor(
      private router: Router,
      private authService: AuthService,
      public modalRef: NzModalRef<WarningPermissionComponent>
  ) { }

  ngOnInit() {
  }

  accept() {
    // localStorage.clear();
    // this.authService.logout();
    // this.router.navigate(['/auth/login']);
    this.modalRef.close();
  }

}
