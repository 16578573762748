import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent implements OnInit {
  isVersionViettel = false;
  constructor(
    private _formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private notification: NzNotificationService,
  ) { }

  ngOnInit() {
    this.notification.config({
      nzPlacement: 'bottomRight',
      nzMaxStack: 1
    });
    this.createForm();
    this.isVersionViettel = environment.isVersionViettel;
  }

  frmForgot: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  validPhone = /^(\+|\d)[0-9]{7,16}$/;

  createForm() {
    this.frmForgot = this._formBuilder.group({
      phone: [null, [
        Validators.required
      ]]
    });
  }

  submitForm() {
    if (this.frmForgot.value.phone.match(this.validPhone)) {
      this.auth.getOTP(this.frmForgot.value)
        .subscribe(
          (res) => {
            if (+res.statusCode === 200) {
              this.notification.create(
                  'success',
                  `Lấy mã OTP thành công`,
                  `Mã OTP đã được gửi đến số điện thoại.`
              );
              localStorage.setItem('tokenForgetPassword', res?.data?.token);
              this.router.navigate(['/auth/reset-pass']);
              return;
            }
            this.notification.error('Có lỗi xảy ra', res.message);
          },
          (error) => {
            this.notification.create(
              'error',
              'Có lỗi xảy ra !!!',
              `${error}`
            );
          });
    } else {
      this.notification.create(
        'error',
        'Có lỗi xảy ra !!!',
        `Số điện thoại không hợp lệ!`
      );
    }
  }
}
