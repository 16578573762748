import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnChanges{
  @Input() config: any;
  @Output() importFile: EventEmitter<any> = new EventEmitter<any>();
  acceptType: '[.csv, .xlsx]';

  constructor() {}
  ngOnChanges(change: SimpleChanges) {
  }
}