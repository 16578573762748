<div class="login">
  <div class="logo"></div>
  <div class="form">
    <form nz-form>
      <div class="title">Liên kết thành công</div>
      <p class="description">Bạn đã liên kết tài khoản thành công và có thể tạo chương trình Loyalty cho tem QR code.</p>
      <div style="text-align: center;">
        <a class="btn-sync-success" routerLink="/loyalty/campaigns">Bắt đầu tạo chương trình</a>
      </div>
    </form>
  </div>
</div>