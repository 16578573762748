<nz-layout style="height: 100%;">
  <nz-sider appShowMenu nzCollapsible [(nzCollapsed)]="isCollapsed && !changeTab " [nzTrigger]="null" style="height: 100%;position: fixed;
    z-index: 99;">
    <div>
      <div style="height: 55px;">
        <div class="logo" [ngClass]="isCollapsed == false ? 'blockLogo' : 'noneLogo'">
          <img class="logo__default" *ngIf="!isCheckAccountVietel" src="/assets/logo_loyalty_color.png"
            routerLink="/loyalty/home">
          <div style="display: flex;" *ngIf="isCheckAccountVietel" class="justify-content-center">
            <!-- <img style="margin: 15px 5px;" src="/assets/images/logo_viettel_white.png" routerLink="/loyalty/home">
            <img style="margin: 15px 5px;" src="/assets/images/icon_icheck_black.png" routerLink="/loyalty/home"> -->
            <img style="margin-top: -10px ; width: 160px; height: 74.39px;" src="/assets/images/logo vgift.jpg"
              routerLink="/loyalty/home">
          </div>
        </div>
      </div>
    </div>
    <!--    <ul class="a" nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">-->
    <!--      <li nz-menu-item routerLink="/loyalty/home" [nzMatchRouter]="true">-->
    <!--        <i [ngClass]="!isCollapsed ? 'iconMenu' : 'iconMenu2'" nz-icon nzType="line-chart" nzTheme="outline"></i>-->
    <!--        <span>Thống kê loyalty</span>-->
    <!--      </li>-->
    <!--      <li nz-menu-item routerLink="/loyalty/campaigns" [nzMatchRouter]="true">-->
    <!--        <i [ngClass]="!isCollapsed ? 'iconMenu' : 'iconMenu2'" nz-icon nzType="appstore" nzTheme="outline"></i>-->
    <!--        <span>Chương trình</span>-->
    <!--      </li>-->
    <!--      <li nz-submenu nzTitle="Tài trợ" nzIcon="sketch">-->
    <!--        <ul>-->
    <!--          <li nz-menu-item routerLink="/loyalty/sponsors/sponsor-me" [nzMatchRouter]="true">Gói tài trợ</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/sponsors/sponsor-management" [nzMatchRouter]="true">Duyệt tài trợ</li>-->
    <!--        </ul>-->
    <!--      </li>-->
    <!--      <li nz-menu-item routerLink="/loyalty/bonus-code" [nzMatchRouter]="true">-->
    <!--        <i [ngClass]="!isCollapsed ? 'iconMenu' : 'iconMenu2'" nz-icon nzType="qrcode" nzTheme="outline"></i>-->
    <!--        <span>Mã dự thưởng</span>-->
    <!--      </li>-->
    <!--      <li nz-submenu nzTitle="Sell-out" nzIcon="shopping">-->
    <!--        <ul>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/points-customer" [nzMatchRouter]="true">Tạo chương trình</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/sell-out" [nzMatchRouter]="true">Danh sách</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/statistical-gift" [nzMatchRouter]="true">Thống kê chỉ số Sell-in</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/statistical-gift" [nzMatchRouter]="true">Thống kê thanh toán</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/statistical-gift" [nzMatchRouter]="true">Thống kê điểm thưởng</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/statistical-gift" [nzMatchRouter]="true">Lịch sử trả thưởng</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/statistical-gift" [nzMatchRouter]="true">Lịch sử đổi điểm</li>-->
    <!--        </ul>-->
    <!--      </li>-->
    <!--      <li nz-menu-item routerLink="/loyalty/orders" [nzMatchRouter]="true">-->
    <!--        <i [ngClass]="!isCollapsed ? 'iconMenu' : 'iconMenu2'" nz-icon nzType="book" nzTheme="outline"></i>-->
    <!--        <span>Đơn hàng</span>-->
    <!--      </li>-->
    <!--      <li nz-menu-item routerLink="/loyalty/gifts" [nzMatchRouter]="true">-->
    <!--        <i [ngClass]="!isCollapsed ? 'iconMenu' : 'iconMenu2'" nz-icon nzType="gift" nzTheme="outline"></i>-->
    <!--        <span>Quà tặng</span>-->
    <!--      </li>-->
    <!--      <li nz-submenu nzTitle="Khách hàng" nzIcon="team">-->
    <!--        <ul>-->
    <!--          <li nz-menu-item routerLink="/loyalty/customers/winners" [nzMatchRouter]="true">Danh sách trúng thưởng</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/customers/statistical" [nzMatchRouter]="true">Thống kê trúng thưởng</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/customers/activities" [nzMatchRouter]="true">Danh sách tham gia</li>-->
    <!--        </ul>-->
    <!--      </li>-->
    <!--      <li nz-submenu nzTitle="Tích điểm thành viên" nzIcon="transaction">-->
    <!--        <ul>-->
    <!--          <li nz-menu-item routerLink="/member/member-gift" [nzMatchRouter]="true">Quà tặng thành viên</li>-->
    <!--          <li nz-menu-item routerLink="/member/member-point" [nzMatchRouter]="true">Điểm khách hàng</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/exchange-gift" [nzMatchRouter]="true">Danh sách đổi quà</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/exchange-gift-store" [nzMatchRouter]="true">Thống kê danh sách đổi quà</li>-->
    <!--        </ul>-->
    <!--      </li>-->
    <!--      <li nz-submenu nzTitle="Điểm tích lũy" nzIcon="transaction">-->
    <!--        <ul>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/points-customer" [nzMatchRouter]="true">Điểm khách hàng</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/gifts-customer" [nzMatchRouter]="true">Danh sách đổi quà</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/accumulate-points/statistical-gift" [nzMatchRouter]="true">Thống kê danh sách đổi quà tại cửa hàng</li>-->
    <!--        </ul>-->
    <!--      </li>-->
    <!--      <li nz-submenu nzTitle="Cấu hình" nzIcon="setting">-->
    <!--        <ul>-->
    <!--          <li nz-menu-item routerLink="/loyalty/settings/staffs" [nzMatchRouter]="true">Nhân viên</li>-->
    <!--          <li nz-menu-item routerLink="/loyalty/settings/distributors" [nzMatchRouter]="true">Đại lý - Cửa hàng</li>-->
    <!--        </ul>-->
    <!--      </li>-->
    <!--    </ul>-->

    <!-- Viettel -->
    <ul *ngIf="isCheckAccountVietel" class="a" nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <ng-container *ngFor="let item of menu">
        <li *ngIf="!item.isChildren && item.isViettelCheck" nz-menu-item [routerLink]="item.routerLink"
          [nzMatchRouter]="true">
          <i [ngClass]="item.class" nz-icon [nzType]="item.nzType" [nzTheme]="item.nzTheme"></i>
          <span>{{item.title}}</span>
        </li>
        <li nz-submenu *ngIf="item.isChildren && item.isViettelCheck " [nzTitle]="item.title" [nzIcon]="item.nzType">
          <ul *ngFor="let child of item.children">
            <li *ngIf="child.isViettelCheck" nz-menu-item [routerLink]="child.routerLink" [nzMatchRouter]="true">
              {{child.title}}
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
    <ul *ngIf="!isCheckAccountVietel" class="a" nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <ng-container *ngFor="let item of menu">
        <li *ngIf="!item.isChildren" nz-menu-item [routerLink]="item.routerLink" [nzMatchRouter]="true">
          <i [ngClass]="item.class" nz-icon [nzType]="item.nzType" [nzTheme]="item.nzTheme"></i>
          <span>{{item.title}}</span>
        </li>
        <li nz-submenu *ngIf="item.isChildren && isCheckTeko && !item.isTeko || item.isChildren && !isCheckTeko" [nzTitle]="item.title" [nzIcon]="item.nzType">
          <ul>
            <li *ngFor="let child of item.children" nz-menu-item [routerLink]="child.routerLink" [nzMatchRouter]="true">
              {{child.title}}</li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </nz-sider>

  <nz-layout class="nzLayoutMenu" [ngClass]="changeTab ?'ccc' :''">
    <nz-header [ngClass]="!isCheckAccountVietel ? 'ant-layout-header' : 'viettel'">
      <i class="trigger" nz-icon [nzType]="isCollapsed && !changeTab ? 'menu-unfold' : 'menu-fold'"
        (click)="changeMenu()"></i>
      <!-- Account expired -->
      <div *ngIf="isExpiredViettel" class="account-expired">
        <span direction="right" height="100px">
          TÀI KHOẢN ĐÃ HẾT HẠN
        </span>
      </div>
      <ul class="float-right box-user-parent">
        <li>
          <span class="selected-user">
            <img class="avatar-user" src="{{url_image}}">
          </span>
        </li>
        <li style="text-align: left">
          <span class="selected-user">
            <span>{{loyaltyUser?.name}}</span>
            <img class="icon-down-user" src="/assets/images/ic_down_18px.png">
          </span>
        </li>
        <div class="box-user">
          <div class="homee">
            <i nz-icon nzType="user" nzTheme="outline"></i><a class="management-user" (click)="updateBusiness()">Thông
              tin tài khoản</a>
          </div>
          <div class="logoutt">
            <i nz-icon nzType="lock" nzTheme="outline"></i><a class="logout" (click)="change.show()">Thay đổi mật
              khẩu</a>
          </div>
          <div class="logoutt">
            <i nz-icon nzType="logout" nzTheme="outline"></i><a class="logout" (click)="logout()">Đăng xuất</a>
          </div>
        </div>
      </ul>
    </nz-header>
    <!-- <nz-content class="app-content-loyalty"> -->
    <nz-content [ngStyle]="{
        height: isCheckAccountVietel ? 'calc(100vh - 90px)' : ''
      }">
      <!-- <nz-content > -->
      <div [ngClass]="!isCheckAccountVietel ?'wapper':'wapper-viettel'">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <!-- footer -->
    <nz-footer class="footer-viettel" *ngIf="isCheckAccountVietel">
      <!-- <div style="width:50%; padding-left:10%">
        <img src="/assets/images/icon_footer_viettel.png" alt="footer-viettel">
        <img  style="margin-left: 10%;" src="/assets/images/icon_footer_icheck.png" alt="footer-icheck">
      </div> -->
      <div class=" contents" style="width: 100%; text-align:center">
        <p style=" margin-top: -10px; ">Sản phẩm được hợp tác và phát triển bởi Viettel Telecom và iCheck </p>
        <!-- <p style="margin-top: -10px !important; margin-left: 25px;">Copyright by iCheck (Bản Quyền thuộc về iCheck)</p> -->
      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>

<app-change-password #change></app-change-password>