import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private notification: NzNotificationService,
    private router: Router,
    public commonService: CommonService
  ) { }
  isVisible = false;
  message = '';
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  phone: string;
  frmChangePass: FormGroup;
  isCheckAccountVietel = false;

  type_input = 'password';
  type_input1 = 'password';

  ngOnInit() {
    this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();
    this.message = '';
    this.notification.config({
      nzPlacement: 'bottomRight'
    });
    this.createForm();
  }

  createForm() {
    this.frmChangePass = this._formBuilder.group({
      // phone: [ this.phone, [Validators.required]],
      oldPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]]
    });
  }

  submitForm() {
    this.message = '';
    for (const key of Object.keys(this.frmChangePass.controls)) {
      this.frmChangePass.controls[key].markAsDirty();
      this.frmChangePass.controls[key].updateValueAndValidity();
    }

    const old_password$ = this.frmChangePass.value.oldPassword ? this.frmChangePass.value.oldPassword.trim() : '';
    const password$ = this.frmChangePass.value.newPassword ? this.frmChangePass.value.newPassword.trim() : '';
    const confirmed_password$ = this.frmChangePass.value.confirmPassword ? this.frmChangePass.value.confirmPassword.trim() : '';
    if (old_password$ === '') {
      this.frmChangePass.controls.oldPassword.setValue(null);
    }
    if (password$ === '') {
      this.frmChangePass.controls.newPassword.setValue(null);
    }
    if (confirmed_password$ === '') {
      this.frmChangePass.controls.confirmPassword.setValue(null);
    }

    if (this.frmChangePass.valid) {
      if (password$ === confirmed_password$) {
        this.authService.changePassword(this.frmChangePass.value).subscribe(
          (res) => {
            if (+res.statusCode === 200) {
              this.isVisible = !this.isVisible;
              this.notification.create(
                'success',
                `Thành công`,
                `Đổi mật khẩu thành công!`
              );
              setTimeout(() => {
                this.authService.logout();
                this.router.navigate(['/auth/login']);
              }, 1000);
            } else {
              this.notification.create(
                'error',
                `Đổi mật khẩu thất bại!`,
                `${res.message}`
              );
            }
          },
          (error) => {
            this.notification.create(
              'error',
              `Đổi mật khẩu thất bại!`,
              `${error}`
            );
          }
        );
      } else {
        this.message = 'Mật khẩu xác nhận phải giống mật khẩu mới';
      }
    }
  }
  show() {
    this.message = '';
    this.frmChangePass.reset();
    this.isVisible = true;
  }
  hide() {
    this.isVisible = false;
  }
  showPass(input) {
    if (input === 'eye') {
      this.type_input = 'text';
    } else {
      this.type_input = 'password';
    }
  }

  showPassOld(input) {
    if (input === 'eye') {
      this.type_input1 = 'text';
    } else {
      this.type_input1 = 'password';
    }
  }
}
