<div class="login">
  <div class="logo"></div>
  <div class="form">
    <form nz-form>
      <div class="title">Liên kết không thành công</div>
      <p class="description">{{message}}</p>
      <div nz-row style="margin:20px;">
        <div nz-col nzSpan="12">
          <button class="cancel" nz-button nzType="default" (click)="cancel()" type="button">Bỏ qua</button>
        </div>
        <div nz-col nzSpan="12">
          <button class="save" nz-button type="button" (click)="syncAgain()">Liên kết lại</button>
        </div>
      </div>
    </form>
  </div>
</div>