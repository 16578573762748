import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from 'src/app/modules/auth/auth.service';
import {Router, ActivatedRoute} from '@angular/router';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {concatMap} from "rxjs/operators";
import {of} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-reset-pass',
    templateUrl: './reset-pass.component.html',
    styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassWordComponent implements OnInit {
    isVersionViettel = false;
    constructor(
        private _formBuilder: FormBuilder,
        private auth: AuthService,
        private router: Router,
        private notification: NzNotificationService
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.isVersionViettel = environment.isVersionViettel;
    }

    frmOTP: FormGroup;
    loading = false;
    submitted = false;
    isNotMatch = false;

    error = '';

    createForm() {
        this.frmOTP = this._formBuilder.group({
            otp: [null, [
                Validators.required
            ]],
            password: [null, [Validators.required]],
            confirmed_password: [null, [Validators.required]],
            phone: [localStorage.getItem('phone')]
        });
    }

    submitForm() {
        this.frmOTP.value.otp = this.frmOTP.value.otp.trim();
        if (this.frmOTP.value.password === this.frmOTP.value.confirmed_password) {
            const body = {
                token: localStorage.getItem('tokenForgetPassword') || '',
                otp: this.frmOTP.value.otp
            };
            this.auth.confirmOtpForgetPassword(body).pipe(concatMap(res => {
                if (+res.statusCode === 200) {
                    const thuongELly = {
                        token: res?.data?.token || '',
                        password: this.frmOTP.value.password,
                        confirmPassword: this.frmOTP.value.confirmed_password
                    };
                    return this.auth.handleForgetPassword(thuongELly);
                }
                this.notification.error('Có lỗi xảy ra', `${res.message}`);
                return of(251997);
            })).subscribe(response => {
                if (response === 251997) {
                    return;
                }
                if (+response.statusCode === 200) {
                    this.notification.create(
                        'success',
                        `Đổi mật khẩu thành công!`,
                        `Hãy đăng nhập lại.`
                    );
                    this.router.navigate(['/auth/login']);
                    return;
                }
                this.notification.error('Có lỗi xảy ra', `${response.message}`);
            }, error => {
                this.notification.error('Có lỗi xảy ra', `${error}`);
            });
        } else {
            this.isNotMatch = true;
        }
    }

}
