import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { RootService } from "./shared/services/root.service";

@Directive({
  selector: '[appShowMenu]',
})
export class LoyaltytDirective {

  constructor(
    private rootService: RootService,
  ) { }

  @Input('appShowMenu') isCollapsed = true;

  @HostListener('mouseenter') onMouseEnter() {
    this.isShow(false);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isShow(true);
  }

  private isShow(isCollapsed: boolean) {
    this.rootService.changeMessage(isCollapsed);
  }

}
