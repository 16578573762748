import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseLoyaltyService } from 'src/app/shared/services/base-loyalty.service';

@Injectable()
export class CampaignService extends BaseLoyaltyService {

  // Change step
  private changeStepSubject = new BehaviorSubject<number>(0);
  getStep = this.changeStepSubject.asObservable();

  // Send object Campaign
  private campaignSubject = new BehaviorSubject<any>(null);
  getCampaign = this.campaignSubject.asObservable();

  changeStep(step: number) {
    this.changeStepSubject.next(step);
  }

  sendCampaign(campaign: any) {
    this.campaignSubject.next(campaign);
  }

  checkNumberInput213(value, isZero = false) {
    const result = { status: false, message: '' };
    if (value !== Number(value)) {
      result.message = 'Đây không phải số';
      result.status = false;
      return result;
    }

    if (Number(value) < 0) {
      result.message = 'Không được nhập số âm!';
      result.status = false;
      return result;
    }

    if (!isZero && value === 0) {
      result.status = false;
      result.message = 'Đây không phải là số!';
      return result;
    }

    result.status = true;
    result.message = 'Đung!';
    return result;
  }

  /*chi lay cac goi ma cua doanh nghiep chua su dung*/
  getPackageCodeUse(query?: any): Observable<any> {
    return this.get<any>(`campaign/package-code/list`, { params: query });
  }

  getCodeByPackage(idPackage, query): Observable<any> {
    return this.get<any>(`/package-code/getcode/${idPackage}`, { params: query });
  }

  getCodeByPackageUpdate(boxGiftId, packageCodeId, query): Observable<any> {
    return this.get<any>(`/campaign/box-gift/${boxGiftId}/package-code/${packageCodeId}/codes`, { params: query });
  }

  getListCodeSelected(listCode, query): Observable<any> {
    const res = listCode.slice(query.offset, query.offset + query.limit);
    const data = {
      count: listCode.length,
      rows: res
    };
    const observable = new Observable(subscriber => {
      subscriber.next(data);
      subscriber.complete();
    });
    return observable;
  }

  /*Danh sách mã trúng thưởng đã chọn với 1 quà*/
  getListCodeBonusByGift(boxGiftId, query): Observable<any> {
    return this.get<any>(`/campaign/box-gift/${boxGiftId}/codes-win`, { params: query });
  }

   /*Xóa mã trúng thưởng khỏi chương trình*/
   deleteBonusCode(boxGiftId, body): Observable<any> {
    return this.delete<any>(`/campaign/box-gift/${boxGiftId}/code-win`, body);
  }

  checkBarcode(body: any): Observable<any> {
    return this.post<any>(`/campaign/check-barcode`, body);
  }

  createCampaign(body: any): Observable<any> {
    return this.post<any>(`/campaign`, body);
  }

  updateCampaign(idCampaign: number, body: any): Observable<any> {
    return this.put<any>(`/campaign/${idCampaign}`, body);
  }

  updateStatus(idCampaign: number, body: any): Observable<any> {
    return this.patch<any>(`/campaign/${idCampaign}/staff-change-status`, body);
  }

  getDetail(id: string): Observable<any> {
    return this.get<any>(`/campaign/${id}`);
  }

  stopCampaign(idCampaign, body): Observable<any> {
    return this.patch<any>(`/campaign/${idCampaign}/staff-change-status`, body);
  }

  activeCampaign(idCampaign, body): Observable<any> {
    return this.patch<any>(`/campaign/${idCampaign}/staff-change-status`, body);
  }
  checkCanCreateCampaignLongTermPoints(): Observable<any> {
     return this.get<any>(`/loyalty/network-information`);
  }
}
