import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sync-faild',
  templateUrl: './sync-faild.component.html',
  styleUrls: ['./sync-faild.component.scss']
})
export class SyncFaildComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        this.message = params.message;
      });
  }

  message;
  syncAgain() {
    this.router.navigate(['/auth/sync-form']);
  }

  cancel() {
    this.router.navigate(['/auth/login']);
  }
}
