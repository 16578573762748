import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { SyncService } from 'src/app/shared/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-sync-form',
  templateUrl: './sync-form.component.html',
  styleUrls: ['./sync-form.component.scss']
})
export class SyncFormComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private syncService: SyncService,
    private notification: NzNotificationService,
  ) { }

  userLoyalty;
  user = {
    userName: null,
    password: null,
    remember: false
  };

  message = '';
  loading = false;
  styleMessage = {
    'margin-top': '10px',
    color: 'red',
    'font-weight': 500,
    'font-size': '13px',
  };
  access_token;
  name_company;


  password = 'password';
  ngOnInit() {
    this.syncService.userLoyalty.subscribe(data => {
      this.access_token = data.access_token;
      this.name_company = data.name;
    });
  }
  showPass(input) {
    if (input === 'eye') {
      this.password = 'text';
    } else {
      this.password = 'password';
    }
  }

  login() {
    this.user.userName = this.checkInputSpace(this.user.userName);
    this.user.password = this.checkInputSpace(this.user.password);
    if (this.user.userName && this.user.userName) {
      this.authService.login(this.user)
        .subscribe(
          (user: any) => {
            if (+user?.statusCode === 200 && user?.data?.token) {
              localStorage.setItem('currentUser', JSON.stringify(user));
              // sau khi login thanh cong vao gateway thi goi api dong bo ben loyalty
              const body = {
                token: this.access_token
              };
              this.syncService.syncQrcodeLoyalty(body).subscribe(
                (result) => {
                  if (result.statusCode === 200) {
                    localStorage.setItem('loyaltyUser', JSON.stringify(result.data));
                    this.router.navigate(['/auth/sync-success']);
                  } else {
                    this.router.navigate(['/auth/sync-faild'], { queryParams: { message: result.message } });
                  }
                },
                (err) => {
                  this.notification.create(
                    'error',
                    'Hệ thống bị lỗi!!!',
                    `${err}`
                  );
                });
            }
          },
          (err) => {
            this.message = 'Thông tin tài khoản không chính xác!';
          });
    }
  }

  checkInputSpace(input) {
    return input.replace(/\s/g, '');
  }
}
