import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseLoyaltyService } from '../base-loyalty.service';

@Injectable({
  providedIn: 'root'
})

export class StampService extends BaseLoyaltyService {

  id = localStorage.getItem('loyaltyUser') ? JSON.parse(localStorage.getItem('loyaltyUser')).account_qrcode_id : '';

  getBatchQRCode(): Observable<any> {
    return this.get<any>(`/qrcode/batch`);
  }

  getPrefix() :Observable<any> {
    return this.get<any>(`/qrcode/prefix`);
  }

  checkPrefix(query?) :Observable<any> {
    return this.get<any>(`/qrcode/prefix-detail`, { params: query });
  }

  getStampOfGift(boxGiftId, query): Observable<any> {
    return this.get<any>(`/campaign/gift/stamp/${boxGiftId}`, { params: query });
  }

  getStampHandMade(body, boxGift?): Observable<any> {
    let boxGiftID = boxGift? `/${boxGift}` : '';
    return this.post<any>(`/campaign/stamp-handmade${boxGiftID}`, body);
  }

  /*Xóa mã trúng thưởng khỏi chương trình*/
  deleteStamp(boxGiftId, body): Observable<any> {
    return this.delete<any>(`/campaign/box-gift/${boxGiftId}/target`, body);
  }
}
