import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPassComponent } from './forgot-pass';
import { ResetPassWordComponent } from './reset-pass';
import { ConfirmOTPComponent } from './confirm-otp';
import { SyncAccountComponent, SyncFormComponent, SyncSuccessComponent, SyncFaildComponent } from './sync-account';
import { BlockUserComponent } from './block-user/block-user.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent},
  { path: 'forgot', component: ForgotPassComponent},
  { path: 'reset-pass', component: ResetPassWordComponent},
  { path: 'confirm-otp', component: ConfirmOTPComponent},
  { path: 'sync-account', component: SyncAccountComponent},
  { path: 'sync-form', component: SyncFormComponent},
  { path: 'sync-success', component: SyncSuccessComponent},
  { path: 'sync-faild', component: SyncFaildComponent},
  { path: 'blocked', component: BlockUserComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
