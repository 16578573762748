import { Injectable } from '@angular/core';
import { BaseLoyaltyService } from '../base-loyalty.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class SyncService extends BaseLoyaltyService {

    private doneObjSubject = new BehaviorSubject<any>({});
    public userLoyalty = this.doneObjSubject.asObservable();

    saveUserLoyalty(obj) {
        this.doneObjSubject.next(obj);
    }

    syncQrcodeLoyalty(body: any): Observable<any> {
        return this.post<any>(`user/sync-account`, body);
    }
}
