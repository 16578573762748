<div class="login">
  <div class="logo"></div>
  <div class="form-container">
    <p class="title">Đăng ký</p>
    <form nz-form [formGroup]="frmSingin" autocomplete="false" novalidate="true"
      (ngSubmit)="submitForm(frmSingin.value)">

      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="last_name">
          <input nz-input formControlName="last_name" placeholder="Họ" class="lastname" type="text" />
          <ng-template #last_name let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập họ</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('minlength')">
              <p class="error"><i>Họ phải có ít nhất 2 chữ cái</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">
              <p class="error"><i>Họ không được quá 50 chữ cái</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('pattern')">
              <p class="error"><i>Họ không hợp lệ</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="first_name">
          <input nz-input formControlName="first_name" placeholder="Tên" class="firstname" type="text" />
          <ng-template #first_name let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập tên</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('minlength')">
              <p class="error"><i>Tên phải có ít nhất 2 chữ cái</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">
              <p class="error"><i>Tên không được quá 50 chữ cái</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('pattern')">
              <p class="error"><i>Tên không hợp lệ</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="phone">
          <input nz-input formControlName="phone" placeholder="Số điện thoại" class="phone" type="text" />
          <ng-template #phone let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập số điện thoại</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('minlength')">
              <p class="error"><i>Số điện thoại tối thiểu phải là 10 số</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">
              <p class="error"><i>Số điện thoại tối đa phải là 10 số</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('pattern')">
              <p class="error"><i>Số điện thoại không hợp lệ</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="email">
          <input nz-input formControlName="email" placeholder="Email" class="email" />
          <ng-template #email let-control>
            <ng-container *ngIf="control.hasError('pattern')">
              <p class="error"><i>Email không hợp lệ</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="password">
          <input nz-input formControlName="password" (keyup)=passKeypress($event) placeholder="Mật khẩu"
            class="password" type="password" />
          <ng-template #password let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập mật khẩu</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="confirm_password">
          <input nz-input formControlName="confirm_password" (keyup)=confirmPassKeypress($event)
            placeholder="Xác nhận mật khẩu" class="password" type="password" />
          <ng-template #confirm_password let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập lại mật khẩu</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <p class="error" *ngIf="!verifi_password && frmSingin.value.password && frmSingin.value.confirm_password ">Mật
        khẩu xác nhận chưa đúng</p>

      <div nz-row style="margin-top: 50px;">
        <div nz-col nzSpan="12">
          <a class="back-login" routerLink="/auth/login">Đăng nhập</a>
        </div>
        <div nz-col nzSpan="12">
          <button class="save" nz-button nzType="default" type="submit">Tiếp tục</button>
        </div>
      </div>
    </form>
  </div>
</div>