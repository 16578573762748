import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { BaseLoyaltyService } from '../base-loyalty.service';

@Injectable({
  providedIn: 'root'
})

export class DistributorService extends BaseLoyaltyService {

  allDistributor(query?: any): Observable<any> {
    return this.get<any>(`/distributor`, { params: query });
  }

  allShopQrcode(query?: any) {
    return this.get<any>(`/qrcode/store`, { params: query });
  }

  create(body: any): Observable<any> {
    return this.post<any>(`/distributor`, body);
  }

  update(id: any, body: any): Observable<any> {
    return this.put<any>(`/distributor?id=${id}`, body);
  }

  destroy(id: any): Observable<any> {
    return this.delete<any>(`/distributor?id=${id}`);
  }

}
