import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { StoreRouterConnectingModule ,routerReducer, RouterStateSerializer} from '@ngrx/router-store';
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { NzButtonModule } from 'ng-zorro-antd/button';

import { JwtInterceptor } from 'src/app/modules/auth/jwt.interceptor';
import { ErrorInterceptor } from 'src/app/modules/auth/error.interceptor';
import { LoyaltyModule} from 'src/app/modules/loyalty/loyalty.module';
import { CustomSerializer } from './shared/utils';
import { StampService } from './shared/services';
import { CommonService } from './shared/services/common/common.service';
import { NgxCaptchaModule } from 'ngx-captcha';

registerLocaleData(en); 

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StoreModule.forRoot(reducers, {metaReducers}),
        StoreModule.forRoot({
            router: routerReducer
        }),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
        EffectsModule.forRoot([AppEffects]),
        StoreRouterConnectingModule.forRoot({stateKey: "router"}),
        EntityDataModule.forRoot(entityConfig),
        HttpClientModule,
        NgZorroAntdModule,
        FormsModule,
        BrowserAnimationsModule,
        NzButtonModule,
        ReactiveFormsModule,
        LoyaltyModule,
        NgxCaptchaModule
    ],
    providers: [
        StampService,
        CommonService,
        {provide: RouterStateSerializer, useClass: CustomSerializer},
        {provide: NZ_I18N, useValue: en_US},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    ],
    exports: [

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
