<div nz-row class="padding margin">
  <div nz-col nzSpan="4">
    <label [class]="required ? 'ant-form-item-required' : ''">
      {{label}}
      <i *ngIf="member.tips" nz-icon nz-tooltip nzTitle="{{tips}}" type="question-circle" theme="outline"></i>
    </label>
  </div>

  <div nz-col nzSpan="20" >
    <nz-select  style="width: 100%;" nzShowSearch nzAllowClear [nzPlaceHolder]="placeholder" [(ngModel)]="value" [nzDropdownRender]="render">
      <nz-option *ngFor="let option of member.options" [nzLabel]="option.label" [nzValue]="option.value"> </nz-option>
    </nz-select>
    <ng-template #render>
      <nz-divider></nz-divider>
      <div class="add-item"><i nz-icon nzType="plus"></i> Add item</div>
    </ng-template>
  </div>

</div>
