import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import {map} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    device_id: string;
    constructor(private authService: AuthService,
                private router: Router) {
        // this.authService.deviceId.subscribe(device_id => this.device_id = device_id);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authService.currentUserValue;
        if (currentUser && currentUser?.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }
        // @ts-ignore
        return next.handle(request).pipe(map((e: HttpEvent<any>) => {
            if (e instanceof HttpResponse) {
                if (e?.body?.statusCode == 401) {
                    localStorage.clear();
                    this.authService.logout();
                    this.router.navigate(['/auth/login']);
                }
            }
            return e;
        }));
    }
}
