import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoyaltyComponent } from './loyalty.component';

const routes: Routes = [
  {
    path: '', component: LoyaltyComponent,
    children: [
      { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'home', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.WinnersModule) },
      { path: 'campaigns', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule) },
      { path: 'gifts', loadChildren: () => import('./gifts/gifts.module').then(m => m.GiftsModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
      { path: 'bonus-code', loadChildren: () => import('./bonus-code/bonus-code.module').then(m => m.BonusCodeModule) },
      { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
      { path: 'sponsors', loadChildren: () => import('./sponsor/sponsor.module').then(m => m.SponsorModule) },
      // tslint:disable-next-line:max-line-length
      { path: 'accumulate-points', loadChildren: () => import('./accumulate-point/accumulate-points.module').then(m => m.AccumulatePointsModule) },
      { path: 'member', loadChildren: () => import('./member-gift/member.module').then(m => m.MemberModule) },
      { path: 'exchange-gift', loadChildren: () => import('./exchange-gift/exchange-gift.module').then(m => m.ExchangeGiftModule) },
      // tslint:disable-next-line:max-line-length
      { path: 'exchange-gift-store', loadChildren: () => import('./exchange-gift-store/exchange-gift-store.module').then(m => m.ExchangeGiftModule) },
      { path: 'sell-out', loadChildren: () => import('./seller/seller.module').then(m => m.SellerModule)},
      // tslint:disable-next-line:max-line-length
      { path: 'voucher-management', loadChildren: () => import('./voucher-management/voucher-management.module').then(m => m.VoucherManagementModule)},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoyaltyRoutingModule { }
