<nz-tabset>
  <nz-tab nzTitle="Upload file">
    <nz-alert *ngIf="uploadError" nzType="error" [nzMessage]="uploadError" nzShowIcon></nz-alert>
    <nz-upload
      [nzMultiple]="multiple"
      [nzSize]="maxSize"
      [nzShowButton]="showUploadButton"
      [nzCustomRequest]="tusUpload"
      (nzChange)="onChange($event)"
      [nzBeforeUpload]="beforeUpload">
      <button nz-button><i nz-icon nzType="upload"></i><span>Click to Upload</span></button>
      <span *ngIf="sizes?.length" style="color: red"> Kích thước:
        {{sizes[0].width}} x
        {{sizes[0].height}} </span>
    </nz-upload>
  </nz-tab>
</nz-tabset>
