import { Injectable } from '@angular/core';
@Injectable()
export class CheckNumberService {
  /**
   * @author Zin
   * @param value Giá trị truyền vào để check "string or number"
   * @param config Object điều kiện của function
   * @param config.zero Truyền vào true or false (True là chấp nhận số 0, False không chấp nhận giá trị có số 0)
   * @param config.decimal Truyền vào true or false (True là chấp nhận số Thập Phân, False không chấp nhận)
   * Lưu ý các giá trị trong config không truyền vào thì mặc định là False
   */
  checkNumberInput(value, config?: any) {
    let result = { message: '', status: true };
    if (!config?.decimal && value != parseInt(value)) {
      result.message = 'Đây không phải số';
      result.status = false;
      return result
    } else if (config?.decimal && value != parseInt(value) && value.split('.').length !== 2) {
      result.message = 'Đây không phải số';
      result.status = false;
      return result
    } else if (`${value}`.split('.').length > 2) {
      result.message = 'Đây không phải số';
      result.status = false;
      return result
    }

    if (parseInt(value) < 0) {
      result.message = 'Không được nhập số âm!';
      result.status = false;
      return result;
    }

    if (!config?.zero && value == 0) {
      result.message = 'Không được nhập số 0!';
      result.status = false;
      return result
    }

    result.message = 'Đúng';
    result.status = true;
    return result;
  }
/**
 * @author Zin
 * @param value nhận vào giá trị
 */
  formatNumberMoney(value) {
    return value.replace(/./g, (c, i, a) => {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
    });
  }
}
