<div class="login">
    <div class="logo"></div>
    <div class="form">
      <div class="title">Tài khoản đã bị khóa</div>
      <nz-form-item style="margin-top: 20px">
        <nz-form-control>
            <p style="text-align: center;">Lý do: <b>{{reason}}</b></p>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="margin-top: 20px">
        <nz-form-control>
            <div nz-col nzSpan="24" style="text-align: center;">
                <button class="back" nz-button (click)="back()" type="submit">Quay lại</button>
            </div>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>