import {Injectable, OnInit} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseLoyaltyService } from '../base-loyalty.service';
import {CommonService} from "../common/common.service";

@Injectable({
    providedIn: 'root'
})

export class GiftSharedService extends BaseLoyaltyService implements OnInit{
    loyaltyUser: any;
    private doneObjSubject = new BehaviorSubject<boolean>(false);
    addGiftDone = this.doneObjSubject.asObservable();

    addDone(done) {
        this.doneObjSubject.next(done);
    }

    listGift(query?: any): Observable<any> {
        const params = query;
        //
        // ICOIN: 'Quà Xu',
        //     PRODUCT: 'Quà hiện vật',
        //     CONGRATULATION: 'Quà tinh thần',
        //     PHONE_CARD: 'Quà thẻ nạp điện thoại',
        //     RECEIVE_STORE: 'Quà nhận tại cửa hàng',
        //     VOUCHER: 'Voucher',
        params.type = this.isCheckAccountByViettel()
            ? 'PRODUCT,CONGRATULATION,RECEIVE_STORE,VOUCHER'
            : 'ICOIN,PRODUCT,CONGRATULATION,PHONE_CARD,RECEIVE_STORE,VOUCHER,VNPAY_QR';
        return this.get<any>(`/gift`, { params });
    }

    listGiftMember(query?: any): Observable<any> {
        return this.get<any>(`/loyalty/gift`, { params: query });
    }

    listGiftSponsor(query?: any): Observable<any> {
        return this.get<any>(`/sponsor/gifts`, { params: query });
    }

    addGift(body: any) {
        return this.post<any>(`/gift`, body);
    }

    addGiftMember(body: any) {
        return this.post<any>(`/loyalty/gift`, body);
    }

    updateGift(body: any, gift: any) {
        return this.put<any>(`/gift/${gift.id}`, body);
    }

    // tslint:disable-next-line:contextual-lifecycle
    ngOnInit(): void {
    }

    isCheckAccountByViettel() {
        this.loyaltyUser = JSON.parse(localStorage.getItem('loyaltyUser'));
        if (this.loyaltyUser?.special_partner_code === 'VIETTEL') {
            // .toLowerCase()
            return this.loyaltyUser?.special_partner_code === 'VIETTEL';
        }
        return false;
    }
}
