<form nz-form [formGroup]="form">
    <div class="contentModal">
        <nz-form-item>
            <span>Tài khoản đã hết hạn sử dụng. Vui lòng liên hệ nhân viên iCheck để gia hạn thêm tài khoản!</span>
        </nz-form-item>
    </div>

    <div class="footerModal">
        <!-- <button nz-button nzType="primary" type="submit" (click)="closeModal()">Không</button> -->
        <button nz-button nzType="primary" type="submit" (click)="openModal()">Đóng</button>
    </div>
</form>

