<div [class.i-input]="!member.is_variant" [class.ant-row]="nzpan==20">
  <div nz-col nzSpan="4" class="i-label" [hidden]="member.hide_label">
    <label [class]="required && label != '' ? 'ant-form-item-required' : ''">
      {{label}}
      <i *ngIf="member.tips" icon nzType="question-circle" nzTheme="outline" nz-popover [nzContent]="help_tips"
        nzPlacement="hover" nzPlacement="bottom"></i>
    </label>
  </div>

  <div nz-col [nzSpan]="nzpan" class="ant-form-item-control i-content" [class.has-error]="error">
    <!--input text - number -->
    <nz-input-group [nzSuffix]="suffixTemplate" *ngIf="member.uiType == 'text'">
      <input nz-input [type]="member.uiType" [placeholder]="placeholder" [required]="required"
        [attr.maxlength]="member.max_length" [attr.minlength]="member.min_length" (blur)="onBlur($event)"
        [(ngModel)]="value" [disabled]="member.readOnly" [style.width]="member.width" (keypress)="_keypress($event)" />
    </nz-input-group>

    <nz-input-group [nzSuffix]="suffixTemplate" *ngIf="member.uiType == 'number'">
      <nz-input-number [type]="member.uiType" [placeholder]="placeholder" [required]="required"
        [attr.maxlength]="member.max_length" [nzMin]="0" [attr.minlength]="member.min_length"
        (ngModelChange)="onChange($event)" [(ngModel)]="value" [disabled]="member.readOnly" [style.width]="member.width"
        (keypress)="_keypress($event)"></nz-input-number>
    </nz-input-group>

    <ng-template #suffixTemplate>
      <span class="label-length" *ngIf="member.max_length && value">{{value.length}}/{{member.max_length}}</span>
    </ng-template>


    <!--input select -->
    <nz-select [style.width]="member.width ? member.width : '100%'" nzShowSearch nzAllowClear
      nzPlaceHolder="{{placeholder}}" [nzMode]="multiple" (keyup)="onBlurSelect($event)"
      (ngModelChange)="onSelectChange($event)" [(ngModel)]="value"
      *ngIf="!is_allow_custom && ((member.uiType == 'select' && options && options.length > 4) || select_force)"
      [nzDisabled]="member.readOnly">
      <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.value"> </nz-option>
    </nz-select>

    <!-- Is Allow custom value -->
    <nz-select [style.width]="member.width ? member.width : '100%'" nzShowSearch nzAllowClear
      nzPlaceHolder="{{placeholder}}" [nzMode]="multiple" (ngModelChange)="onSelectChange($event)"
      [nzDropdownRender]="renderCustomValue" [(ngModel)]="value" *ngIf="is_allow_custom && member.uiType == 'select'"
      [nzDisabled]="member.readOnly">
      <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.value"> </nz-option>
    </nz-select>

    <ng-template #renderCustomValue>
      <input nz-input placeholder="Thêm giá trị" class="input-custom-value" (keyup.enter)="addCustomValue($event)"
        [(ngModel)]="new_value" />
    </ng-template>

    <!--input suggest -->
    <nz-select [style.width]="member.width ? member.width : '100%'" nzShowSearch nzServerSearch nzAllowClear
      nzPlaceHolder="{{placeholder}}" [nzMode]="multiple" (nzBlur)="onBlurSuggest($event)"
      (ngModelChange)="changeSuggest($event)" (nzOnSearch)="suggest($event)" [(ngModel)]="value"
      *ngIf="member.uiType == 'suggest'" [nzDisabled]="member.readOnly">
      <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.value"> </nz-option>
    </nz-select>

    <!--input textarea -->
    <nz-input-group [nzSuffix]="suffixTemplate_textarea" *ngIf="member.uiType == 'textarea'">
      <textarea nz-input rows="2" [attr.maxlength]="member.max_length" [attr.minlength]="member.min_length"
        [placeholder]="placeholder" [(ngModel)]="value" (blur)="onBlur($event)"
        [disabled]="member.readOnly">{{value}}</textarea>
    </nz-input-group>
    <ng-template #suffixTemplate_textarea>
      <span class="label-length" *ngIf="member.max_length && value">{{value.length}}/{{member.max_length}}</span>
    </ng-template>

    <!--input textarea-customer -->
    <ck-editor [(ngModel)]="value" skin="moono-lisa" language="en" (change)="onChangeCustomEditor($event)"
      *ngIf="member.uiType == 'custom_editor'" [readonly]="member.readOnly" [config]="editorConf">
    </ck-editor>

    <!--Checkbox-->
    <nz-checkbox-group [(ngModel)]="options" (ngModelChange)="checkbox(options)" (blur)="checkbox(options)"
      *ngIf="member.uiType == 'select' && options && options.length <= 4 && multiple == 'multiple' && !select_force && !is_allow_custom"
      [nzDisabled]="member.readOnly"></nz-checkbox-group>

    <!--Radio-->
    <nz-radio-group [(ngModel)]="value"
      *ngIf="member.uiType == 'select' && options && options.length <= 4 && multiple == 'default' && !select_force && !is_allow_custom"
      [nzDisabled]="member.readOnly" (ngModelChange)="changeRadio()">
      <label nz-radio [nzValue]="o.value" *ngFor="let o of options">{{ o.label }}</label>
    </nz-radio-group>

    <!--ERROR-->
    <ng-container *ngIf="member.type == 'package_group'">
      <div nz-row>
        <!--<nz-input-group [nzSuffix]="package_group">-->
        <nz-input-group>
          <ng-container *ngFor="let child of member.children">
            <div nz-col nzSpan=8>
              <input nz-input [type]="child.type" [placeholder]="child.placeholder || child.name"
                [required]="child.required" [attr.maxlength]="child.max_length" [attr.minlength]="child.min_length"
                (blur)="onBlur($event)" [(ngModel)]="value[child.code]" [disabled]="child.readOnly" />
            </div>
          </ng-container>
        </nz-input-group>
      </div>
    </ng-container>

    <!--Datetime-->
    <ng-container *ngIf="member.type == 'datetime'">
      <nz-date-picker [(ngModel)]="member.value" nzFormat="dd-MM-yyyy" (ngModelChange)="onChangeDate($event)" [style.width]="member.width"
        [disabled]="member.readOnly" [nzPlaceHolder]="member.placeholder"></nz-date-picker>
    </ng-container>

    <!--Boolean-->
    <ng-container *ngIf="member.type == 'boolean'">
      <nz-switch [(ngModel)]="value" (ngModelChange)="onChangeBoolean($event)"></nz-switch>
    </ng-container>

    <div nz-row *ngIf="error" class="row-error">
      {{message}}
    </div>
    <!--HElp-->
    <ng-template #help_tips>
      <div [innerHTML]="tips" style="width:300px;">
      </div>
    </ng-template>
  </div>
</div>
