import { environment } from "src/environments/environment";
import * as _ from 'lodash';
export function removeAllSpacingBetweenWords(str: string) {
  return str.replace(/\s+/g, ' ').trim();
}

export function removeEmptyQuery(query: any) {
  Object.keys(query).forEach(key => {
    if (query[key] === undefined || query[key] === null || query[key] === '') {
      delete query[key];
    }
  });
  return query;
}

export function getArrayNotDuplicateAfterCompareTwoArray(arr1: any[], arr2: any[], type?: string) {
  if (type === 'id') { return arr1.filter(e => !arr2.includes(e.id)); }
  if (type === 'name') { return arr1.filter(e => !arr2.includes(e.name)); }
  return arr1.filter(e => !arr2.includes(e));
}

export function getArrayDuplicateAfterCompareTwoArray(arr1: any[], arr2: any[], type?: string) {
  if (type === 'id') { return arr1.filter(e => arr2.includes(e.id)); }
  if (type === 'name') { return arr1.filter(e => arr2.includes(e.name)); }
  return arr1.filter(e => arr2.includes(e));
}

export function operationArray(arr: any[]) {
  let subtract = arr[0];
  for (let i = 1; i < arr.length; i++) {
    subtract -= arr[i];
  }
  const max = Math.max(...arr);
  const min = Math.min(...arr);
  const sum = arr.reduce((a, b) => a + b, 0);
  const average = arr.reduce((a, b) => a + b, 0) / arr.length;
  return {
    max,
    min,
    subtract,
    sum,
    average
  };
}
export function formatPhone84(phone: string) {
  if (!phone) return phone;
  phone = phone.replace(/\D+/gm, "");
  phone = phone.replace(/^0/, "84");
  phone = phone.replace(/^(?!84)/, "84");
  return phone;
}

export function getUserInput() {
  const metadataString = localStorage.getItem('metadata');
  if (!metadataString) return null;
  const metadata: any[] = JSON.parse(metadataString);
  const userInput: any = metadata ? metadata.filter(x => x.key === 'inputUser') : null;
  if (userInput && userInput[0]) return userInput[0].value;
  return null;
}
export function getMetadataRequest(metadata: any[]) {
  const metadataString = localStorage.getItem('metadata');
  if (!metadataString) return metadata;
  const metadataJson: any[] = JSON.parse(metadataString);
  const metadataRequest = metadataJson.filter(x => x.key !== 'inputUser');
  if (metadata) {
    return _.uniqBy(metadata.concat(metadataRequest));
  }
  return metadataRequest;
}
export function phongVuCheck(campaign: any) {
  if (!campaign) return false;
  if (!environment.phongvu) return false;
  if (!environment.phongvu.isEnable) return false;
  if (!environment.phongvu.userIds.includes(campaign.owner.id)) return false;
  return true;
}

// export function vnpayCheck(campaign: any) {
//   if (!campaign) { return false; }
//   if (!environment.vnpay) {return false; }
//   if (!environment.vnpay.isEnable) {return false; }
//   if (!environment.vnpay.userIds.includes(campaign.owner.id)) {return false; }
// }
