import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from '../../../services/base.service';

@Component({
  selector: 'i-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit, OnChanges {

  @Input()
  member: any = {};
  public tips: string;
  public name: string;
  public required: boolean;
  public value: any;
  public placeholder: string;
  public nzpan: number = 24;
  public error: boolean = false;
  public code: string;
  public message: string = "";
  public multiple: string = "default";
  public label: string;
  public show_search: boolean = true;
  public options: any = [];
  public uiType: string;
  public select_force: boolean = false;
  public old_value: any;
  @Input() index_variant: any;
  @Input() mode: string = 'add'

  public is_allow_custom: boolean = false;
  public new_value: any;

  public editorConf = {
    toolbarGroups: [
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      '/',
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'forms', groups: ['forms'] },
      '/',
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] }
    ],

    removeButtons: 'Replace,Scayt,Undo,Source,Save,NewPage,Preview,Print,Templates,CopyFormatting,RemoveFormat,BidiLtr,BidiRtl,Language,Flash,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,SelectAll,Strike,Subscript,Superscript,CreateDiv,Blockquote,Maximize,ShowBlocks,About,Smiley,SpecialChar'
  };

  @Output() valueChange = new EventEmitter();

  constructor(private baseService: BaseService) {
  }

  ngOnInit() {
    this.is_allow_custom = this.member.is_allow_custom || false;
    this.placeholder = this.member.placeholder || this.member.name;
    this.tips = this.member.tips;
    this.required = this.member.required || false;
    this.code = this.member.code;
    this.nzpan = this.member.layout == 'half' ? 8 : 20;
    this.error = false;
    this.code = this.member.code;
    this.multiple = this.member.type == "multi_select" ? 'multiple' : 'default';
    this.label = this.member.name;
    this.show_search = this.member.show_search ? true : false;
    this.options = this.member.options || [];

    if (this.member.type == 'package_group') {
      this.value = {};
      this.member.children.forEach(e => {
        this.value[e.code] = e.value;
        if (e.required) {
          this.required = true;
        }
      });
    } else {
      setTimeout(() => {
        if (this.member.type == 'suggest') {
          if (this.mode == 'edit') {
            this.value = this.member.value ? this.member.value.id : undefined;
            this.old_value = this.member.value ? this.member.value.id : undefined;
          }
        } else {
          this.value = this.member.value || undefined;
          this.old_value = this.member.value || undefined;

          if (Array.isArray(this.value)) {
            this.value = _.map(this.value, e => {
              return parseInt(e) || e;
            })
          } else if (this.value) {
            this.value = parseInt(this.value) || this.value;
          }
        }

        if (this.member.type == 'enum' || (this.member.type == 'select' && this.multiple == 'default')) {
          this.value = parseInt(this.value) || this.value;
        }

        if (this.member.uiType == 'select' || this.member.type == 'enum') {
          this.value = parseInt(this.value) || this.value;
          this.options = _.map(this.options, e => {
            let f = {
              value: e.id,
              label: e.value
            }

            if (Array.isArray(this.value) && this.value.indexOf(f.value) > -1) {
              f['checked'] = true;
            }
            return f;
          })
        }
      }, 500);
    }

    if (this.member.type == 'suggest') {
      this.suggest('');
    }

    if (this.member.type == 'enum') {
      this.select_force = true;
      this.nzpan = 8;
      this.member.uiType = 'select';
      this.multiple = 'default';
    } else {
      this.member.uiType = this.member.type == "multi_select" ? 'select' : this.member.type;
    }


  }

  onBlur(obj) {
    this.error = false;
    let value = obj.target.value;
    if (this.required && (!value || value == undefined)) {
      this.error = true;
      this.message = "Xin vui lòng nhập"
    } else if (this.member.uiType == 'email') {
      let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

      if (value != "" && (value.length <= 5 || !EMAIL_REGEXP.test(value))) {
        this.error = true;
        this.message = "Xin vui lòng nhập đúng email"
      } else {
        this.emitData(value);
      }
    } else {
      if (this.member.type == 'package_group') {
        this.emitData(this.value);
      } else
        this.emitData(value);
    }
  }

  onChange(obj) {
    this.error = false;
    let value = obj;
    if (this.required && (!value || value == undefined)) {
      this.error = true;
      this.message = "Xin vui lòng nhập"
    } else {
      if (this.member.type == 'package_group') {
        this.emitData(this.value);
      } else
        this.emitData(value);
    }
  }

  checkbox(obj: any): void {
    this.value = [];
    this.error = false;

    obj.forEach(e => {
      if (e.checked) {
        this.value.push(e.value);
      }
    });
    if (this.required && this.value.length == 0) {
      this.error = true;
      this.message = "Xin vui lòng lựa chọn"
    } else {
      this.emitData(this.value);
    }
  }

  changeRadio(): void {
    this.emitData(this.value);
  }

  changeSelectBox(obj): void {
    this.emitData(obj);
  }

  onBlurSelect(obj) {
    this.error = false;
    if ((this.value == "" || this.value == [""]) && this.required) {
      this.error = true;
      this.message = "Xin vui lòng lựa chọn"
    }
  }

  onSelectChange(obj) {
    this.error = false;
    this.value = obj;
    if ((this.value == "" || this.value == [""]) && this.required) {
      this.error = true;
      this.message = "Xin vui lòng lựa chọn"
    } else {
      let _tmp;
      if (Array.isArray(this.value)) {
        _tmp = [];
        this.value.forEach(e => {
          if (e && e != "") {
            _tmp.push(e);
          }
        });
        this.emitData(_tmp);
      } else {
        this.emitData(this.value);
      }
    }
  }

  onBlurSuggest(obj) {
    this.error = false;
    if ((!this.value || this.value == "" || this.options.length == 0) && this.required) {
      this.error = true;
      this.message = "Xin vui lòng lựa chọn"
    }
  }

  changeSuggest(obj) {
    this.error = false;
    if ((!obj || obj == "" || this.options.length == 0) && this.required) {
      this.error = true;
      this.message = "Xin vui lòng lựa chọn"
    } else {
      this.emitData(obj);
    }
  }


  onChangeDate(result: Date): void {
    this.emitData(result);
  }

  onChangeBoolean($event: any): void {
    this.emitData($event);
  }

  onChangeCustomEditor($event) {
    if (this.required && $event.length == 0) {
      this.error = true;
      this.message = "Xin vui lòng nhập"
    } else {
      this.emitData($event);
    }
  }

  suggest($event) {
    let type = 'onChange';
    let events = this.member.events || [];
    let event = _.find(events, { type: type });
    if (event) {
      if (event.method == 'get') {
        this.baseService.get<any>(event.url + '?search=' + $event).subscribe(data => {
          this.options = data.rows
        })
      } else if (event.method == 'post') {
        this.baseService.post<any>(event.url, { search: event }).subscribe(data => {
          this.options = data.rows
        })
      }
    }
  }

  _keypress(obj: any) {
    if (obj.code == 'Enter') {
      this.error = false;
      let value = obj.target.value;
      if (this.required && (!value || value == undefined)) {
        this.error = true;
        this.message = "Xin vui lòng nhập"
      } else if (this.member.uiType == 'email') {
        let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

        if (value != "" && (value.length <= 5 || !EMAIL_REGEXP.test(value))) {
          this.error = true;
          this.message = "Xin vui lòng nhập đúng email"
        } else {
          this.emitData(value);
        }
      } else {
        if (this.member.type == 'package_group') {
          this.emitData(this.value);
        } else
          this.emitData(value);
      }
    }
  }

  emitData(value) {
    this.old_value = this.member.value;
    this.member.value = value;
    this.member.old_value = this.old_value;
    if (this.member.uiType == 'select') {
      let option;
      this.options.forEach(e => {
        if (e.value == value) option = e;
      });
      if (option) {
        this.member.value_label = option.label;
      }
    }
    if (this.index_variant) {
      this.member.index_variant = this.index_variant;
    }
    if (this.member.obj_variant) {
      let index = _.findIndex(this.member.obj_variant, { code: this.member.code });
      this.member.obj_variant[index].value = value;
      if (this.member.uiType == 'select') {
        this.member.obj_variant[index].value_label = this.member.value_label;
      }
    }
    this.valueChange.emit(this.member)
  }

  ngOnChanges() {
  }

  addCustomValue($event) {
    if (!$event || !$event.target || !$event.target.value || $event.target.value.trim() == '') return;
    let value = $event.target.value.trim();
    let is_exist = false;
    this.options.forEach(e => {
      if (e.value == value) {
        is_exist = true;
      }
    });
    if (!is_exist) {
      // let key = this.nonAccentVietnamese(value);
      // key = key.replace(/\s/g, '_');
      let key = value;
      let payload = {
        value: key,
        label: value,
        is_new_value: true
      };
      this.options.push(payload)
      this.new_value = undefined;
      if (this.multiple == 'multiple') {
        if (Array.isArray(this.value)) {
          this.value.push(key)
        } else {
          this.value = [key];
        }
      } else {
        this.value = key;
      }
      this.onSelectChange(this.value);
    }
  }

  nonAccentVietnamese(str) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  }

}
