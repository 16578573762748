<form nz-form [formGroup]="form">
    <div class="contentModal">
        <nz-form-item>
            <span>Thời gian diễn ra chương trình vượt quá thời hạn tài khoản của bạn. Nếu tài khoản hết hạn,
                chương trình sẽ bị dừng hoạt động. Bạn chắc chắn muốn tiếp tục không ? </span>
        </nz-form-item>
    </div>

    <div class="footerModal">
        <button nz-button nzType="primary" type="submit" (click)="closeModal()">Không</button>
        <button nz-button nzType="primary" type="submit" (click)="OpenModal()">Có</button>
    </div>
</form>

