import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RootService extends BaseService {
  messageSource = new BehaviorSubject<boolean>(true);
  changeTab = new BehaviorSubject<boolean>(false);
  currentMessage = this.messageSource.asObservable();
  currentChangeTab = this.changeTab.asObservable();

  changeMessage(message) {
    this.messageSource.next(message);
  }

  saveChangeTab(changeTab) {
    this.changeTab.next(changeTab);
  }

  getCities(): Observable<any> {
    return this.http.get<any>(`${environment.apiPublicLoyalty}/cities?limit=100`);
  }

  // API danh sách cửa hàng
  getStores(query?: any): Observable<any> {
    return this.http.get<any>(`${environment.apiPublicLoyalty}/campaign/list/store`, { params: query });
  }

  getCampaign(campaignId) {
    return this.http.get<any>(`${environment.apiPublicLoyalty}/campaign/get-campaign?campaign_code=${campaignId}`);
  }

  getDistricts(cityId): Observable<any> {
    return this.http.get<any>(`${environment.apiPublicLoyalty}/districts?city_id=${cityId}&limit=100`);
  }

  getWards(districtId): Observable<any> {
    return this.http.get<any>(`${environment.apiPublicLoyalty}/wards?district_id=${districtId}&limit=100`);
  }

  getiCheckUser(search) {
    if (search.length > 8) {
      return this.get<any>(`/users?search=${search}`);
    }
    return this.get<any>(`/users/${search}/profile`);
  }

  getiCheck() {
    return this.get<any>(`/users`);
  }

  // teko
  checkCanCreateCampaignLongTermPoints(): Observable<any> {
    return this.get<any>(`/api/business/loyalty/cms/loyalty/network-information`);
  }
}
