import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {NzModalRef} from 'ng-zorro-antd';
import { CampaignService } from 'src/app/modules/loyalty/campaign/services';

@Component({
  selector: 'app-modal-out-date',
  templateUrl: './modal-out-date.component.html',
  styleUrls: ['./modal-out-date.component.scss']
})
export class ModalOutDateComponent implements OnInit {
  @Input() show: boolean;
  @Input() titleModal;
  form = this.fb.group({});
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public modalRef: NzModalRef<ModalOutDateComponent>,
    private campaignService: CampaignService,
  ) { }

  @Input() date: any;

  ngOnInit(): void {
  }

  closeModal() {
    this.modalRef.close();
    // this.campaignService.changeStep(0);
    // this.router.navigate(['/auth/login']);
  }

  OpenModal() {
    this.modalRef.close('yes');
    console.log('date');
  }

}
