import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UploadService {

  private baseUrl = 'https://upload.icheck.vn';
  private baseUrl2 = 'https://apis.icheck.vn/upload/image/v1';
  private baseUrl3 = 'https://upload.icheck.com.vn/upload/stream';

  constructor(private http: HttpClient) { }

  public create(size: number, metadata: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/upload`, { size, metadata });
  }

  public postImg(file: any): Observable<any> {
    const body = new FormData();
    body.append('my_field', 'file');
    body.append('my_file', file)
    // return this.http.post(`${this.baseUrl}/upload/stream`, body);
    return this.http.post(`${this.baseUrl3}`, body);

  }

  public postImg2(body: any): Observable<any> {
    return this.http.post(`${this.baseUrl3}`, body);
  }

  public getFile(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/files/${id}`, { params: { fields: ['source', 'metadata'] } });
  }

}
