import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private otpSubject = new BehaviorSubject<any>({});
    otpObj = this.otpSubject.asObservable();

    private deviceSubject = new BehaviorSubject<any>({});
    deviceId = this.deviceSubject.asObservable();

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.getDeviceId();

    }
    getDeviceId() {
        // nhận deviceId từ url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let deviceId = urlParams.get('deviceId');

        if (!deviceId || deviceId === 'null' || deviceId === 'undefined') {
            deviceId = localStorage.getItem('device_id');
        }
        if (!deviceId || deviceId === 'null' || deviceId === 'undefined') {
            FingerprintJS.load().then(fp => {
                fp.get().then(async result => {
                    deviceId = await result.visitorId;
                    localStorage.setItem('device_id', deviceId);
                    this.deviceSubject.next(deviceId);
                });
            });
        } else {
            localStorage.setItem('device_id', deviceId);
            this.deviceSubject.next(deviceId);
        }
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    setOTPObject(body) {
        this.otpSubject.next(body);
    }

    login(body: any) {
        return this.http.post<any>(`${environment.apiCoreV2}/login`, body)
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user?.data));
                this.currentUserSubject.next(user?.data);
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('loyaltyUser');
        localStorage.clear();
        this.currentUserSubject.next(null);
    }

    // quen mk
    getOTP(query: any) {
        return this.http.get<any>(`${environment.apiCoreV2}/api/noauth/user/password/forgot/request`, { params: query })
            .pipe(map(otp => {
                localStorage.setItem('phone', query.phone);
                return otp;
            }));
    }
    confirmOtpForgetPassword(body) {
        return this.http.post<any>(`${environment.apiCoreV2}/api/noauth/user/password/forgot/confirm`, body);
    }
    handleForgetPassword(body) {
        return this.http.post<any>(`${environment.apiCoreV2}/api/noauth/user/password/forgot/change`, body);
    }
    // end quen mk

    // thay doi mk
    changePassword(body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiCoreV2}/api/user/password`, body);
    }
    // end doi mk

    searchUserByName(name?) {
        const body: any = {
            limit: 10,
            offset: 0
        }
        if (name) body.filterString = name;
        return this.http.post<any>(`${environment.apiCoreV2}/social/api/users/query`, body);
    }
}
