import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.frmSingin = _formBuilder.group({
    });
  }

  frmSingin: FormGroup;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  verifi_password;

  ngOnInit() {
    this.createForm();
    this.returnUrl = '/auth/login';
  }

  createForm() {
    this.frmSingin = this._formBuilder.group({
      first_name: [null, [
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.required,
        Validators.pattern('[a-zA-ZàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴĐ ]*')
      ]],
      last_name: [null, [
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.required,
        Validators.pattern('[a-zA-ZàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđÀÁẠẢÃÂẦẤẨẪẬĂẰẮẲẴẶÈÉẺẼẸÊỀẾỂỄỆÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴĐ ]*')
      ]],
      phone: [null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('[0-9]*')
      ]],
      email: [null, [
        Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')
      ]],
      password: [null, [
        Validators.required,
      ]],
      confirm_password: [null, [
        Validators.required
      ]],
    });
  }

  confirmPassKeypress(event) {
    let password = this.frmSingin.value.password;
    let confirm_password = event.target.value;
    if (password !== confirm_password) {
      this.verifi_password = false;
    } else { this.verifi_password = true; }
  }

  passKeypress(event) {
    let confirm_password = this.frmSingin.value.confirm_password;
    let password = event.target.value;
    if (password !== confirm_password) {
      this.verifi_password = false;
    } else { this.verifi_password = true; }
  }

  submitForm(user) {
    for (const key of Object.keys(this.frmSingin.controls)) {
      this.frmSingin.controls[key].markAsDirty();
      this.frmSingin.controls[key].updateValueAndValidity();
    }

    if (user.name === '' || user.phone === '' || user.username === '' || user.password === '' || user.confirm_password === '') {
      return;
    }

    if (user.password !== user.confirm_password) {
      return;
    }

    if (this.frmSingin.valid) {
    }
  }

}
