import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseLoyaltyService } from '../base-loyalty.service';

@Injectable({
  providedIn: 'root'
})

export class WinnersService  extends BaseLoyaltyService {

  listWinner(query?: any): Observable<any> {
    return this.get<any>(`/winner`, { params: query });
  }

  updateStatusRecieveGift(body, idWinner): Observable<any> {
    return this.patch<any>(`/winner/${idWinner}`, body);
  }

  changeStatus(body, idWinner): Observable<any> {
    return this.patch<any>(`/winner/${idWinner}/change-status`, body);
  }

  getActivities(query?: any): Observable<any> {
    return this.get<any>(`/campaign/user/activities`, { params: query });
  }

  listStatistical(query?: any): Observable<any> {
    return this.get<any>(`/winner/statistic-winners`, {params: query});
  }
}
