import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseLoyaltyService } from '../base-loyalty.service';

@Injectable({
    providedIn: 'root'
})

export class StaffsService extends BaseLoyaltyService {

    listStaff(query?: any): Observable<any> {
        return this.get<any>(`/staff`, { params: query });
    }

    createStaff(body: any): Observable<any> {
        return this.post<any>(`/staff`, body);
    }

    permissionsByStaff(idStaff): Observable<any> {
        return this.get<any>(`/user/${idStaff}/list-permission`);
    }

    setPermissionsStaff(idStaff, body): Observable<any> {
        return this.post<any>(`/user/${idStaff}/assign-permission`, body);
    }

    deleteStaff(staff): Observable<any> {
        return this.delete<any>(`staff/${staff.id}`);
    }
    findUserSocialByIdOrPhone(id, phone) {
        if(id) return this.get<any>(`/user/social/get-user-info-by-id/${id}`);
        return this.get<any>(`/user/social/get-user-info-by-phone/${phone}`);
    }
}
