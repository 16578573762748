// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiCore: 'https://apiv2.dev.icheck.vn',
  apiCoreV2: 'https://api-loyalty.dev.icheck.vn',
  // apiLoyalty: 'https://api.dev.icheck.vn/api/business/loyalty/cms',
  // apiPublicLoyalty: 'https://api.dev.icheck.vn/api/business/public/loyalty',
  // apiNotPublicLoyalty: 'https://api.dev.icheck.vn/api/business/loyalty',
  // apiLoyalty: 'https://apiv2.dev.icheck.vn/api/business/loyalty/cms',
  // apiPublicLoyalty: 'https://apiv2.dev.icheck.vn/api/business/public/loyalty',
  // apiNotPublicLoyalty: 'https://apiv2.dev.icheck.vn/api/business/loyalty',
  apiLoyalty: 'https://api-loyalty.dev.icheck.vn/api/business/loyalty/cms',
  apiPublicLoyalty: 'https://api-loyalty.dev.icheck.vn/api/business/public/loyalty',
  apiNotPublicLoyalty: 'https://api-loyalty.dev.icheck.vn/api/business/loyalty',
  urlImage: 'https://upload.icheck.vn/hi/',
  url_static_image: 'http://icheckcdn.net/images/720x720',
  url_mkt: 'http://cd-dev.qcheck.vn/',
  openAppiCheck: 'https://icheckdev.page.link/Home',
  urlWebapp: 'http://webapp-loyalty.dev.icheck.vn/',
  isVersionViettel: false,
  phongvu: {
    isEnable: true,
    userIds: [71],
    landingUrl: 'https://phongvu-landing.dev.icheck.vn'
  },
  tracking_id_teko: 'b5b91db8-66e4-4138-be5e-a234cfbc97d4'
  // urlWebapp: 'http://localhost:8100/'
  // urlWebapp: 'http://10.10.1.105:8100/'
  // urlWebapp: 'http://10.10.1.220:4444/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


