import { Injectable } from '@angular/core';
import { BaseLoyaltyService } from '../base-loyalty.service';
import { Observable } from 'rxjs';
import { Business } from '../../models/business';

@Injectable({
  providedIn: 'root'
})

export class BusinessService  extends BaseLoyaltyService {

  updateBusiness(body: Business): Observable<any> {
    return this.put<any>(`/user`, body);
  }
}
